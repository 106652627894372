import * as React from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    LabelList,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {KpiInfoList} from "../../api/metrics/kpiInfoList";
import {Strings} from "../../locales/strings";
import {round} from "../../utils/numberUtils";
import Colors from '../../utils/colors';
import BlurView from "../../components/BlurView";
import {PageStatus} from "../pageStatus";
import {ErrorCard} from "./ErrorCard";
import {LoadingCard} from "./LoadingCard";

// TODO: Move to colors module.
const COLORS = [
    Colors.primary,
    '#2362b7',
    '#BDE2B9',
    '#7CC674',
    '#4CB140',
    '#38812F'
];

const customLabel = ({x, y, payload}: any) => {

    const rows = payload.value.split('\n');

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill={Colors.textMedium} fontSize={13}>
                <tspan textAnchor="middle" x="0">{rows[0]}</tspan>
                <tspan textAnchor="middle" x="0" dy="20">{rows[1]}</tspan>
            </text>
        </g>
    );
};

// TODO: Convert to function that takes an array of numbers.
function getMaxValue(obj: any[]) {

    let value = 0;

    obj.forEach(function (item) {
        if (item.value > value) {
            value = item.value;
        }
    });

    return value;
}

function getMinValue(obj: any[]) {

    let value = 0;

    obj.forEach(function (item) {
        if (item.value < value) {
            value = item.value;
        }
    });

    return value;
}

function getPlaceholderData() {
    return [
        {
            "name": "Company\nCurrent",
            "value": .2
        },
        {
            "name": "Company\nPrognosis",
            "value": -0.4
        },
        {
            "name": "Industry\n25%",
            "value": .5
        },
        {
            "name": "Industry\n50%",
            "value": -0.5
        },
        {
            "name": "Industry\n75%",
            "value": .4
        },
        {
            "name": "Industry\nPrognosis",
            "value": -0.2
        }
    ];
}

function Chart({data}: any) {

    let yDomainMin = 0;
    let yDomainMax = 0;

    const minVal = getMinValue(data);
    yDomainMin = minVal * 1.15;
    yDomainMin = round(yDomainMin, 3);

    const maxVal = getMaxValue(data);
    yDomainMax = maxVal * 1.15;
    yDomainMax = round(yDomainMax, 3);

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    width={300}
                    height={300}
                    data={data}
                    // tickCount={6}
                    margin={{
                        top: 20,
                        right: 0,
                        left: -15,
                        bottom: 25
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="name"
                        tick={customLabel}
                        interval={0}
                        color={Colors.textMedium}/>
                    <YAxis
                        stroke={Colors.textMedium}
                        tick={{fontSize: 12}}
                        type="number"
                        domain={[yDomainMin, yDomainMax]}/>
                    <Tooltip/>
                    <ReferenceLine y={0} stroke={Colors.lineMedium}/>

                    <Bar dataKey="value" barSize={35}>
                        <LabelList
                            dataKey="value"
                            fill={Colors.textMedium}
                            position="top"
                        />
                        {
                            data.map((_entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}

export default function PrognosisIndustryChart(
    {status, data, kpiInfoList, kpiKey, isAuth}: {
        status: PageStatus,
        data: any,
        kpiInfoList: KpiInfoList | null,
        kpiKey: string,
        isAuth: boolean
    }) {

    let template = <></>;
    let title = '';

    if (status === PageStatus.IsReady || status === PageStatus.IsSubmitting) {
        title = 'Generating analysis...';
        template = <LoadingCard description1={Strings.CHART_GENERATING_ANALYSIS}/>;
    } else if (status === PageStatus.HasSubmitted && data !== null) {
        const kpiName = kpiInfoList?.getKpiNameLong(kpiKey) || '';

        if (isAuth) {
            title = `${kpiName}`;
            template = <Chart data={data[kpiKey]}/>;
        } else {
            title = `Upgrade to access ${kpiName}`;
            template = <BlurView><Chart data={getPlaceholderData()}/></BlurView>;
        }

    } else if (status === PageStatus.HasError) {
        title = 'Error';
        template = <ErrorCard description2="Unable to load chart."/>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}