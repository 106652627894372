import * as React from 'react';
import {Link, useLocation} from "react-router-dom";
import {useAuth} from "../../AuthProvider";
import {PageEndpoints} from "../pageEndpoints";
import LogoText from "../../components/LogoText";

export default function Header() {
    const location = useLocation();
    const {isAuth} = useAuth();

    const showSignIn = location.pathname !== PageEndpoints.userSignIn;
    const showSignUp = location.pathname !== PageEndpoints.userSignUp;

    return (
        <header
            className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between px-1 px-sm-3 px-md-5 py-3 fixed-top">

            <div className="col-3 col-sm-4">
                <Link to={PageEndpoints.home}
                      className="d-flex align-items-center col-md-3 text-dark text-decoration-none">
                    <LogoText/>
                </Link>
            </div>

            <div className="col-5 col-sm-4">
                <ul className="nav col-12 col-md-auto justify-content-center">
                    <li>
                        <Link to={PageEndpoints.home} className="nav-link px-1 px-sm-2 link-secondary">
                            Home
                        </Link>
                    </li>

                    <li>
                        <Link to={PageEndpoints.publicPricing} className="nav-link px-1 px-sm-2 link-secondary">
                            Pricing
                        </Link>
                    </li>
                    <li>
                        <Link to={PageEndpoints.publicFaqs} className="nav-link px-1 px-sm-2 link-secondary">
                            FAQs
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="col-4 col-sm-4 text-end">
                {
                    !isAuth() &&
                    <>
                        {
                            showSignIn &&
                            <Link to={PageEndpoints.userSignIn} className="me-2 me-lg-3 link-secondary">
                                Sign in
                            </Link>
                        }

                        {
                            showSignUp &&
                            <Link to={PageEndpoints.userSignUp} className="me-0 me-lg-3 link-secondary">
                                Sign up
                            </Link>
                        }

                        <Link to={PageEndpoints.publicContactUs} className="btn btn-primary d-none d-lg-inline">
                            Request demo
                        </Link>
                    </>
                }

                {
                    isAuth() &&
                    <Link to={PageEndpoints.userSignOut} className="link-secondary">
                        Sign out
                    </Link>
                }
            </div>
        </header>
    );
}