import * as React from 'react';
import {useEffect} from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../../AuthProvider";
import {PageEndpoints} from "../pageEndpoints";
import Logo from "../../components/Logo";
import {PageTitles} from '../../locales/pageTitles';

export default function Home() {
    const location = useLocation();
    const {isAuth} = useAuth();

    useEffect(() => {
        document.title = PageTitles.home;
    }, []);

    if (isAuth()) {
        return <Navigate to={PageEndpoints.analysis} replace={true} state={location.state}/>;
    }

    return (
        <div className="home">
            <div className="bg-light py-5">
                <div className="pb-1">
                    <Logo/>
                </div>
                <div className="h1 mb-4">
                    Financial prognosis<br/>and benchmarking
                </div>
                <h1 className="header-subtitle">
                    Analyze your current financial statements to predict your future performance.
                </h1>
            </div>

            <div className="bg-white py-5">
                <div className="row justify-content-center">
                    <div className="col-8">

                        <div className="row mb-4">
                            <div className="col">
                                <h2>
                                    How it Works
                                </h2>
                            </div>
                        </div>

                        <div className="row justify-content-start">
                            <div className="col-auto">
                                <div className="mt-4"><i className="bi bi-box-arrow-in-right"></i></div>
                            </div>
                            <div className="col-5">
                                <h3 className="mt-1">1. Enter financial statements.</h3>
                                <p>
                                    Enter your quarterly financial statements&mdash;balance sheet,
                                    income statement, and statement of cashflows.
                                </p>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col-auto">
                                <div className="mt-4"><i className="bi bi-gear"></i></div>
                            </div>
                            <div className="col-5">
                                <h3 className="mt-1">2. CFOdigital performs AI analysis.</h3>
                                <p>
                                    Using AI Neural Networks, CFOdigital performs an in-depth analysis of your
                                    financial data.
                                </p>
                            </div>
                        </div>

                        <div className="row justify-content-end mt-2">
                            <div className="col-auto">
                                <div className="mt-4"><i className="bi bi-clipboard2-pulse"></i></div>
                            </div>
                            <div className="col-5">
                                <h3 className="mt-1">3. Receive your prognosis.</h3>
                                <p>
                                    Within seconds, you receive a detailed financial prognosis with
                                    benchmarking data compared vs your industry.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="bg-light py-5">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <div className="row mb-4">
                            <div className="col">
                                <h2>
                                    See it for Yourself
                                </h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <Link to={PageEndpoints.publicContactUs} className="btn btn-primary">
                                    Request demo
                                </Link>
                            </div>

                            <div className="col-auto">
                                <Link to={PageEndpoints.publicContactUs} className="btn btn-white">
                                    View pricing
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

