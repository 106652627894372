import * as React from 'react';
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {Link} from "react-router-dom";
import {Strings} from "../../locales/strings";
import {PageEndpoints} from "../pageEndpoints";
import {Company} from "../../api/companies/company";

export default function ReportHeader(
    {pretitle, company, showAddReport, isAddReportAuthorized}: {
        pretitle: string,
        company: Company | null,
        showAddReport: boolean,
        isAddReportAuthorized: boolean
    }) {

    let title = 'No companies';
    let sectorName = null;
    let hasCompany = false;

    if (company) {
        hasCompany = true;
        title = company.companyName;
        sectorName = company.sectorName;
    }

    return (
        <div className="header reports">
            <div className="row align-items-end d-flex align-items-center">
                <div className="col">
                    <h6 className="header-pretitle">
                        {pretitle}
                    </h6>
                    <h1 className="header-title">
                        {title}
                        {hasCompany && (
                            <span className="badge bg-primary-subtle">{sectorName}</span>
                        )}
                    </h1>
                </div>
                <div className="col-auto">
                    {
                        hasCompany && showAddReport && !isAddReportAuthorized && (
                            <OverlayTrigger
                                placement="auto"
                                delay={{show: 0, hide: 700}}
                                overlay={(
                                    <Popover>
                                        <Popover.Body>
                                            {Strings.FEATURE_LIMIT}
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                <Button
                                    variant="primary"
                                    size="lg"
                                >
                                    Add report
                                </Button>
                            </OverlayTrigger>
                        )
                    }

                    {
                        hasCompany && showAddReport && isAddReportAuthorized && (
                            <Link to={PageEndpoints.reportsAdd} className="btn btn-primary btn-lg">
                                Add report
                            </Link>
                        )
                    }
                </div>
            </div>
        </div>
    );
}