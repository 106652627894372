import {KpiKey} from "./kpiKey";

export class KpiPrognosis implements KpiKey {
    kpiKey: string = '';
    kpiName: string = '';
    kpiPrognosisValue: number | null = null;
    kpiCcpValue: number | null = null;
    kpiCategoryKey: string = '';
    kpiCategoryName: string = '';

    static fromJson(json: any): KpiPrognosis {
        const obj = new KpiPrognosis();

        obj.kpiKey = json.kpi_key;
        obj.kpiName = json.kpi_name;
        obj.kpiPrognosisValue = json.kpi_prognosis_value;
        obj.kpiCcpValue = json.kpi_ccp_value;
        obj.kpiCategoryKey = json.kpi_category_key;
        obj.kpiCategoryName = json.kpi_category_name;

        return obj;
    }
}