export class Currency {
    currencyCode: string = '';
    currencyName: string = '';

    static fromJson(json: any): Currency {
        const obj = new Currency();

        obj.currencyCode = json.currency_code;
        obj.currencyName = json.currency_name;

        return obj;
    }
}