import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {useAuth} from "../AuthProvider";
import {useCompany} from "../CompanyProvider";
import {postData} from "../api/postData";
import {getHeaders} from "../api/requestHeaders";
import {Company} from "../api/companies/company";
import {getCurrentCompany} from "../api/companies/companyUtils";
import {PageStatus} from "../pages/pageStatus";
import {Report} from "../api/reports/report";

export default function useCompanyReport(companyId: string) {
    const {getToken} = useAuth();
    const {getCompanies} = useCompany();

    const [status, setStatus] = useState(PageStatus.IsLoading);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    const [company, setCompany] = useState<Company | null>(null);
    const [reportList, setReportList] = useState<Report[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async () => {
            if (status !== PageStatus.IsLoading) {
                return;
            }

            const companyList = getCompanies();
            const co = getCurrentCompany(companyList, companyId);
            setCompany(co);

            if (co === null) {
                setStatus(PageStatus.IsMissingCompany);
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const data = {
                'company_id': co.companyId
            };

            const reportResults = await postData(ApiEndpoints.companyReportsList, data, headers, signal);

            if (doExitEarly(reportResults)) {
                return;
            }

            const reportListResults = reportResults.results;
            const reports: Report[] = [];

            reportListResults.forEach(function (r: any) {
                reports.push(Report.fromJson(r));
            });

            setReportList(reports);

            if (reports.length === 0) {
                setStatus(PageStatus.IsMissingReport);
                return;
            }

            setStatus(PageStatus.IsLoaded);
        };

        postForm().then();

        return () => {
            controller.abort();
        };

    }, [status, getCompanies, companyId, getToken]);

    return {
        status, message, validationErrors, company, reportList
    };
}