import {Kpi} from "./kpi";
import {KpiCategoryKeys} from "./kpiCategoryKeys";
import {KpiKey} from "./kpiKey";
import {KPI_KEYS_ORDER} from "./kpiKeysOrder";

export function sort(kpis: KpiKey[]) {

    const sortedKpis: KpiKey[] = [];

    KPI_KEYS_ORDER.forEach(function (kpiKey: string) {
        kpis.forEach(function (kpi: KpiKey) {
            if (kpi.kpiKey === kpiKey) {
                sortedKpis.push(kpi);
                return;
            }
        });
    });

    return sortedKpis;
}

export class KpiList {
    kpis: Kpi[] = [];

    static fromJson(json: any): KpiList {
        const obj = new KpiList();
        const kpiList: Kpi[] = [];

        json.forEach(function (item: any) {
            kpiList.push(Kpi.fromJson(item));
        });

        // Sort according to UI requirements.
        obj.kpis = sort(kpiList) as Kpi[];

        return obj;
    }

    getCategoryName(categoryKey: KpiCategoryKeys) {
        let name = '';

        this.kpis.forEach(function (kpi: Kpi) {
            if (kpi.kpiCategoryKey === categoryKey) {
                name = kpi.kpiCategoryName;
                return;
            }
        });

        return name;
    }

    getKpisByCategory(categoryKey: KpiCategoryKeys) {
        const results: Kpi[] = [];

        this.kpis.forEach(function (kpi: Kpi) {
            if (kpi.kpiCategoryKey === categoryKey) {
                results.push(kpi);
            }
        });

        return results;
    }
}