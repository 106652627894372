import * as React from "react";
import {useAuth} from "../../AuthProvider";
import {EntitlementTypes} from "../../api/entitlementTypes";
import NoCompaniesExist from "../../components/NoCompaniesExist";
import NoReportsExist from "../../components/NoReportsExist";
import PageError from "../../components/PageError";
import Status from "../../components/Status";
import {PageStatus} from "../pageStatus";
import useCompanyReport from "../../hooks/useCompanyReport";
import ReportContent from "./ReportContent";
import ReportHeader from "./ReportHeader";

export function ReportList(
    {companyId}: {
        companyId: string
    }) {

    const {getEntitlement} = useAuth();
    const {status, message, company, reportList} = useCompanyReport(companyId);

    const authorizedReportCount = getEntitlement(EntitlementTypes.REPORTS_COUNT) || 1;

    const isAddReportAuthorized =
        authorizedReportCount === -1 ||
        reportList.length < authorizedReportCount;

    let template;

    if (status === PageStatus.IsLoading) {
        template = (<Status message="Loading reports"/>);
    } else if (status === PageStatus.HasError) {
        template = (<PageError message={message}/>);
    } else if (status === PageStatus.IsMissingCompany) {
        template = (<NoCompaniesExist/>);
    } else if (status === PageStatus.IsMissingReport) {
        template = (<NoReportsExist/>);
    } else {
        template = (<ReportContent company={company} reportList={reportList}/>);
    }

    return (
        <>
            <ReportHeader
                pretitle="Financial Reports"
                company={company}
                showAddReport={true}
                isAddReportAuthorized={isAddReportAuthorized}
            />
            {template}
        </>
    );
}