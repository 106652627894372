import * as React from "react";
import {useEffect} from "react";
import {PageTitles} from "../../locales/pageTitles";
import {UserAccountEdit} from "./UserAccountEdit";
import UserAccountHeader from "./UserAccountHeader";

export function UserAccountRoot() {
    useEffect(() => {
        document.title = PageTitles.userAccount;
    }, []);

    return (
        <div className="p-4 account-settings" id="accountRoot">
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-xl-8 col-xxl-6">
                    <UserAccountHeader/>
                    <UserAccountEdit/>
                </div>
            </div>
        </div>
    );
}
