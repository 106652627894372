import {KpiKey} from "./kpiKey";

export class KpiInfo implements KpiKey {
    kpiKey: string = '';
    kpiName: string = '';
    kpiNameLong: string = '';
    kpiCategoryKey: string = '';
    kpiCategoryName: string = '';

    static fromJson(json: any): KpiInfo {
        const obj = new KpiInfo();

        obj.kpiKey = json.kpi_key;
        obj.kpiName = json.kpi_name;
        obj.kpiNameLong = json.kpi_name_long;
        obj.kpiCategoryKey = json.kpi_category_key;
        obj.kpiCategoryName = json.kpi_category_name;

        return obj;
    }
}