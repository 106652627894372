import * as React from 'react';
import {Strings} from "../../locales/strings";
import {PageStatus} from "../pageStatus";
import {ErrorCard} from "./ErrorCard";
import {KpiAggChart} from "./KpiAggChart";
import {LoadingCard} from "./LoadingCard";

export default function KpiAggCard({title, status, kpisVsIndustry, categoryKey}: {
    title: string,
    status: PageStatus,
    kpisVsIndustry: any,
    categoryKey: string
}) {

    let template = <></>;

    if (status === PageStatus.IsReady || status === PageStatus.IsSubmitting) {
        template = <LoadingCard description1={Strings.CHART_GENERATING_ANALYSIS}/>;
    } else if (status === PageStatus.HasSubmitted && kpisVsIndustry !== null) {
        template = <KpiAggChart kpisVsIndustry={kpisVsIndustry} categoryKey={categoryKey}/>;
    } else if (status === PageStatus.HasError) {
        template = <ErrorCard description2="Unable to load chart."/>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}
