import * as React from "react";
import {Link} from "react-router-dom";
import {formatCurrency} from "../../utils/numberUtils";
import {PageEndpoints} from "../pageEndpoints";
import {SubscriptionInterval} from "../../api/products/subscriptionInterval";
import {Product} from "../../api/products/product";
import {ProductFeature} from "../../api/products/productFeature";
import {ProductPrice} from "../../api/products/productPrice";

const categorizeFeatures = (product: Product) => {
    const categorizedProducts: any = {};

    product.features.forEach(function (item: ProductFeature) {
        const category = item.categoryName;

        if (!(category in categorizedProducts)) {
            categorizedProducts[category] = [];
        }

        const productList = categorizedProducts[category];
        productList.push(item);
    });

    return categorizedProducts;
};

export default function ProductCard(
    {product, showMonthly, selectedProductId, onSelect}: {
        product: Product,
        showMonthly: boolean,
        selectedProductId: string,
        onSelect: any
    }) {

    const categorizedFeatures = categorizeFeatures(product);
    const productName = product.productNameShort;
    const isPremium = productName.toLowerCase() === 'premium';

    const buttonText =
        isPremium
            ? 'Contact Sales'
            : 'Select ' + productName;

    const getProductPrice = (): ProductPrice | null => {
        let p: ProductPrice | null = null;

        if (product.prices.length === 0) {
            return null;
        }

        if (product.prices.length === 1) {
            return product.prices[0];
        }

        product.prices.forEach(function (price: ProductPrice) {
            if (showMonthly && price.recurringInterval === 'month') {
                p = price;
                return;
            } else if (!showMonthly && price.recurringInterval !== 'month') {
                p = price;
                return;
            }
        });

        return p;
    };

    const productPrice = getProductPrice();
    const interval = productPrice === null ? '' : productPrice.recurringInterval;
    const displayInterval = productPrice === null ? '' : '/ month';

    const getPrice = (interval: string) => {
        if (interval === '') {
            return 'Contact us';
        } else if (interval === SubscriptionInterval.MONTH) {
            return productPrice === null ? '' : formatCurrency(productPrice.amount, 0);
        } else {
            return productPrice === null ? '' : formatCurrency(productPrice.amount / 12, 0);
        }
    };

    const titleClass = interval === '' ? 'card-title contact' : 'card-title';

    const GetCheck = () => {
        return (
            <i className="bi bi-check-circle text-success"></i>
        );
    };

    const GetX = () => {
        return (
            <i className="bi bi-x-lg text-danger"></i>
        );
    };

    const formatValue = (value: any) => {
        if (typeof value === 'boolean') {
            if (value) {
                return (<GetCheck/>);
            }

            return (<GetX/>);
        }

        if (typeof value === 'number' && value === -1) {
            return 'Unlimited';
        }

        return value;
    };

    const handleOnChange = () => {
        onSelect(product.productId);
    };

    return (
        <div className="card text-center h-100">
            <div className="card-header">
                <h2>{productName} Plan</h2>
            </div>
            <div className="card-body">
                <h3 className={titleClass}>{getPrice(interval)}</h3>
                <p className="card-text">{displayInterval}</p>
            </div>
            <ul className="list-group list-group-flush">
                {

                    Object.keys(categorizedFeatures).map(
                        (categoryName: string) => (
                            <>
                                {
                                    categoryName &&
                                    <li key={categoryName} className="list-group-item category">
                                        <div className="row">
                                            <div className="col">
                                                {categoryName}
                                            </div>
                                        </div>
                                    </li>
                                }

                                {categorizedFeatures[categoryName].map(
                                    (item: ProductFeature) => (
                                        <li key={item.featureId} className="list-group-item">
                                            <div className="row">
                                                <div className="col">{item.featureName}</div>
                                                <div className="col-auto">{formatValue(item.featureValue)}</div>
                                            </div>
                                        </li>
                                    )
                                )}
                            </>
                        )
                    )
                }
            </ul>

            <div className="card-footer">
                {
                    isPremium &&
                    <Link
                        to={PageEndpoints.publicContactUs}
                        className="btn btn-outline-light">
                        {buttonText}
                    </Link>
                }

                {
                    !isPremium &&
                    <>
                        <input
                            type="radio"
                            className="btn-check"
                            name="options-base"
                            id={productName}
                            autoComplete="off"
                            checked={product.productId === selectedProductId}
                            onChange={handleOnChange}
                        />
                        <label className="btn btn-outline-light" htmlFor={productName}>{buttonText}</label>
                    </>
                }
            </div>
        </div>
    );
}