import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {getFieldToFocus} from "../../utils/pageUtils";
import {PageEndpoints} from '../pageEndpoints';
import FormError from "../../components/FormError";
import SubmitButton from "../../components/SubmitButton";
import {PageStatus} from "../pageStatus";
import {PageTitles} from '../../locales/pageTitles';
import {userVerificationValidationSchema} from "../../api/user/userValidationSchema";
import useUserVerify from "../../hooks/useUserVerify";

export default function UserEmailVerification() {
    const navigate = useNavigate();
    const {status, message, validationErrors, submitForm, formData} = useUserVerify();

    useEffect(() => {
        document.title = PageTitles.userEmailVerificationStatus;
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userVerificationValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    /* Get email and code from link. */
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const code = new URLSearchParams(search).get('code');

    /* Set defaults, if they exist. */
    useEffect(() => {
        reset({
            email_address: email,
            verification_code: code
        }, {keepDefaultValues: true});
    }, [reset, email, code]);

    useEffect(() => {
        const defaultField = 'email_address';

        if (status === PageStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, defaultField);
            setFocus(fieldName);

        } else if (status === PageStatus.IsReady) {
            setFocus(defaultField);
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === PageStatus.HasSubmitted) {
            navigate(PageEndpoints.userSignIn, {state: {'emailAddress': formData.email_address}});
        }
    }, [navigate, status, formData]);

    const onSubmitHandler = async (data: any) => {
        submitForm(data);
    };

    return (
        <div className="container account py-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <h1 className="card-title">Verify your email.</h1>
                    <FormError status={status} message={message}/>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <fieldset id="me" disabled={status === PageStatus.IsSubmitting}>
                            <div className="form-floating">
                                <input {...register('email_address')} autoFocus type="text" className="form-control"
                                       id="floatingEmail" placeholder="name@example.com"/>
                                <label htmlFor="floatingEmail">Email address</label>
                                <small className="text-danger">
                                    {errors?.email_address?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('verification_code')} type="text" className="form-control"
                                       id="floatingVerificationCode" placeholder="123456"/>
                                <label htmlFor="floatingVerificationCode">Verification code</label>
                                <small className="text-danger">
                                    {errors?.verification_code?.message?.toString()}
                                </small>
                            </div>
                            <div>
                                <SubmitButton
                                    isFormReady={isFormReady}
                                    featureStatus={status}
                                    text="Verify email"
                                />
                            </div>
                        </fieldset>
                    </form>
                    <div className="row">
                        <div className="col-6">
                            <Link to={PageEndpoints.userEmailVerificationRequest} className="float-start">
                                Resend email
                            </Link>
                        </div>
                        <div className="col-6">
                            <Link to={PageEndpoints.userSignIn} className="float-end">
                                Sign in
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}