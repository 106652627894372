export enum KpiCategoryKeys {
    CASHFLOW = 'cashflow',
    IAICOC = 'iaicoc',
    DAYS = 'days',
    LEVERAGE = 'leverage',
    LIQUIDITY = 'liquidity',
    LYCA = 'lyca',
    PROFITABILITY = 'profitability',
    RETURN = 'return',
    ROA2BOND = 'roa2bond',
    TURNOVER = 'turnover'
}
