export class UserSubscription {
    planId: string | null = '';
    subscriptionStatus: string | null = '';
    isActive: boolean = false;
    currentPeriodEndDate: string | null = null;
    cancelAtPeriodEnd: boolean = false;

    static fromJson(json: any): UserSubscription {
        const obj = new UserSubscription();

        obj.planId = json.plan_id;
        obj.subscriptionStatus = json.subscription_status;
        obj.isActive = json.is_active;
        obj.currentPeriodEndDate = json.current_period_end_date;
        obj.cancelAtPeriodEnd = json.cancel_at_period_end;

        return obj;
    }
}