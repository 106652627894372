import {KpiKey} from "./kpiKey";

export class KpiAggregate implements KpiKey {
    kpiKey: string = '';
    kpiName: string = '';
    kpiCategoryKey: string = '';
    kpiCategoryName: string = '';
    aggKey: string = '';
    aggValue: number | null = null;

    static fromJson(json: any): KpiAggregate {
        const obj = new KpiAggregate();

        obj.kpiKey = json.kpi_key;
        obj.kpiName = json.kpi_name;
        obj.kpiCategoryKey = json.kpi_category_key;
        obj.kpiCategoryName = json.kpi_category_name;
        obj.aggKey = json.agg_key;
        obj.aggValue = json.agg_value;

        return obj;
    }
}