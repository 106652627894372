export enum EntitlementTypes {
    PROGNOSES_PROFITABILITY = 'prognoses_profitability',
    PROGNOSES_LIQUIDITY = 'prognoses_liquidity',
    PROGNOSES_RETURN = 'prognoses_return',
    PROGNOSES_CASHFLOW = 'prognoses_cashflow',

    BENCHMARKS_SECTOR = 'benchmarks_sector',
    BENCHMARKS_SUBSECTOR = 'benchmarks_subsector',

    REPORTS_COUNT = 'reports_count',
    REPORTS_IMPORT = 'reports_import',
    REPORTS_EXPORT = 'reports_export',

    COMPANIES_COUNT = 'companies_count'
}