import * as React from "react";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useCompany} from "../../CompanyProvider";
import {PageTitles} from "../../locales/pageTitles";
import {Company} from "./Company";

export function CompanyRoot() {
    const location = useLocation();
    const {getCurrentCompanyId} = useCompany();
    let companyId = getCurrentCompanyId();

    useEffect(() => {
        document.title = PageTitles.company;
    }, []);

    if (location.pathname === '/add-company') {
        companyId = '';
    }

    // Use key to ensure tree is different for each company.
    return (
        <div className="p-4 content company" id="companyRoot">
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-xl-10 col-xxl-6">
                    <Company key={companyId} companyId={companyId}/>
                </div>
            </div>
        </div>
    );
}
