export class Industry {
    sectorKey: string = '';
    sectorName: string = '';
    subsectorKey: string = '';
    subsectorName: string = '';

    static fromJson(json: any): Industry {
        const obj = new Industry();

        obj.sectorKey = json.sector_key;
        obj.sectorName = json.sector_name;
        obj.subsectorKey = json.subsector_key;
        obj.subsectorName = json.subsector_name;

        return obj;
    }
}