import {ProductFeature} from "./productFeature";
import {ProductPrice} from "./productPrice";

export class Product {
    productId: string = '';
    productName: string = '';
    productNameShort: string = '';
    features: ProductFeature[] = [];
    prices: ProductPrice[] = [];

    static fromJson(json: any): Product {
        const obj = new Product();

        obj.productId = json.product_id;
        obj.productName = json.product_name;
        obj.productNameShort = json.product_name.replace('CFOdigital', '').trim();

        if (json.features !== null) {
            json.features.forEach(function (o: any) {
                obj.features.push(ProductFeature.fromJson(o));
            });
        }

        if (json.prices !== null) {
            json.prices.forEach(function (o: any) {
                obj.prices.push(ProductPrice.fromJson(o));
            });
        }

        return obj;
    }
}