import {createContext} from 'react';
import {User} from "./api/user/user";

export interface UserAuth {
    isAuth: () => boolean;
    signIn: (jwtToken: string, jwtExpiresIn: number) => void;
    signOut: () => void;

    getToken: () => string;

    setUser: (user: any) => void;
    getUser: () => User | null;

    getEntitlement: (entitlement_key: string) => any | null;
}

const defaultContext: UserAuth = {
    isAuth: () => false,
    signIn: () => {
    },
    signOut: () => {
    },

    getToken: () => '',

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUser: (user) => {
    },

    getUser: () => null,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getEntitlement: (entitlement_key) => null,
};

const AuthContext = createContext(defaultContext);

export {AuthContext};