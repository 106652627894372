export function formatDate(date: string | null) {

    if (date === null) {
        return '';
    }

    const dt = Date.parse(date);

    return new Intl.DateTimeFormat(
        undefined,
        {
            dateStyle: 'medium'
        }).format(dt);
}

export function subtractYears(date: any, years: number) {
    date.setFullYear(date.getFullYear() - years);
    return date;
}
