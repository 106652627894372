import {enumValues} from "../../utils/enumUtils";
import {round} from "../../utils/numberUtils";
import {Kpi} from "../../api/metrics/kpi";
import {KpiAggregate} from "../../api/metrics/kpiAggregate";
import {KpiAggregateList} from "../../api/metrics/kpiAggregateList";
import {KpiCategoryKeys} from "../../api/metrics/kpiCategoryKeys";
import {KPI_CORE_KEYS_ORDER} from "../../api/metrics/kpiCoreKeysOrder";
import {KpiList} from "../../api/metrics/kpiList";
import {KPI_KEYS_ORDER} from "../../api/metrics/kpiKeysOrder";
import {KpiPrognosis} from "../../api/metrics/kpiPrognosis";
import {KpiPrognosisList} from "../../api/metrics/kpiPrognosisList";

export function getCcpDict(
    kpiPrognosisList: KpiPrognosisList | null): any | null {

    if (kpiPrognosisList === null) {
        return null;
    }

    const results: any = {};

    kpiPrognosisList.kpiPrognoses.forEach(function (item: KpiPrognosis) {
        const key = item.kpiKey;

        results[key] = {
            'kpiKey': item.kpiKey,
            'kpiName': item.kpiName,
            'kpiCcpValue': item.kpiCcpValue
        };
    });

    return results;
}

export function getKpiVsAggsDict(
    kpiList: KpiList,
    kpiAggregateList: KpiAggregateList): any | null {

    if (kpiList === null || kpiAggregateList === null) {
        return null;
    }

    // Combine both lists by kpi key.
    const kpisByKeyTemp: any = {};

    kpiList.kpis.forEach(function (item: Kpi) {
        kpisByKeyTemp[item.kpiKey] = item;
    });

    // Update kpis with industry agg info.
    kpiAggregateList.kpiAggregates.forEach(function (item: KpiAggregate) {
        const existingItem = kpisByKeyTemp[item.kpiKey];
        const aggKey = 'agg' + item.aggKey.replace('%', '') + 'Value';
        const source: any = {};
        source[aggKey] = item.aggValue;
        kpisByKeyTemp[item.kpiKey] = Object.assign({}, existingItem, source);
    });

    // Reorder as per Ricardo.
    const kpisByKey: any = {};

    KPI_KEYS_ORDER.forEach(function (kpi_key: string) {
        Object.keys(kpisByKeyTemp).forEach(temp_key => {
            if (kpi_key === temp_key) {
                kpisByKey[temp_key] = kpisByKeyTemp[temp_key];
            }
        });
    });

    // Divide by KPI category key.
    const kpiCategoryKeys = enumValues(KpiCategoryKeys);
    const companyKpisByCategory: any = {};

    kpiCategoryKeys.forEach(function (categoryKey: string) {
        companyKpisByCategory[categoryKey] = [];
    });

    Object.keys(kpisByKey).forEach(key => {
        const item = kpisByKey[key];

        item.kpiValue = round(item.kpiValue, 3);
        item.agg25Value = round(item.agg25Value, 3);
        item.agg50Value = round(item.agg50Value, 3);
        item.agg75Value = round(item.agg75Value, 3);

        companyKpisByCategory[item.kpiCategoryKey].push(item);
    });

    return companyKpisByCategory;
}

export function getPrognosisVsIndustryDict(
    companyKpis: KpiList | null,
    companyPrognoses: KpiPrognosisList | null,
    industryKpiAggs: KpiAggregateList | null,
    industryPrognoses: KpiPrognosisList | null) {

    if (companyKpis === null || companyPrognoses === null ||
        industryKpiAggs === null || industryPrognoses === null) {
        return null;
    }

    // TODO: Get keys from list.
    const prognosis: any = {
        'cash_ratio': [],
        'operating_margin': [],
        'net_margin': [],
        'ocg': [],
        'roa': [],
        'roe': []
    };

    companyKpis.kpis.forEach(function (item: Kpi) {
        if (KPI_CORE_KEYS_ORDER.indexOf(item.kpiKey) === -1) {
            return;
        }

        prognosis[item.kpiKey].push({
            'name': 'Company\nCurrent',
            'value': round(item.kpiValue, 3)
        });
    });

    companyPrognoses.kpiPrognoses.forEach(function (item: KpiPrognosis) {
        prognosis[item.kpiKey].push({
            'name': 'Company\nPrognosis',
            'value': round(item.kpiPrognosisValue, 3)
        });
    });

    industryKpiAggs.kpiAggregates.forEach(function (item: KpiAggregate) {
        if (KPI_CORE_KEYS_ORDER.indexOf(item.kpiKey) === -1) {
            return;
        }

        // The agg_keys are returned in order: 25, 50, 75.
        prognosis[item.kpiKey].push({
            'name': 'Industry\n' + item.aggKey,
            'value': round(item.aggValue, 3)
        });
    });

    industryPrognoses.kpiPrognoses.forEach(function (item: KpiPrognosis) {
        if (KPI_CORE_KEYS_ORDER.indexOf(item.kpiKey) === -1) {
            return;
        }

        prognosis[item.kpiKey].push({
            'name': 'Ind Avg\nPrognosis',
            'value': round(item.kpiPrognosisValue, 3)
        });
    });

    return prognosis;
}

export function getSeddaDict(
    companyKpis: KpiList | null,
    companyPrognoses: KpiPrognosisList | null,
    industryKpiAggs: KpiAggregateList | null,
    industryPrognoses: KpiPrognosisList | null) {

    if (companyKpis === null || companyPrognoses === null ||
        industryKpiAggs === null || industryPrognoses === null) {
        return null;
    }

    const seddaResult: any = {};

    // Get company current values.
    companyKpis.kpis.forEach(function (item: Kpi) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const w: any = {
                'current_value': item.kpiValue,
                'predicted_value': 0
            };

            const x: any[] = [];
            x.push(w);

            // industry

            const ind: any = {
                'current_value': 0,
                'predicted_value': 0
            };

            const i: any[] = [];
            i.push(ind);

            seddaResult[key] = {
                'company': x,
                'industry': i
            };
        }

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const w: any = {
                'current_value': item.kpiValue,
                'predicted_value': 0
            };

            const x: any[] = [];
            x.push(w);

            // industry

            const ind: any = {
                'current_value': 0,
                'predicted_value': 0
            };

            const i: any[] = [];
            i.push(ind);

            seddaResult[key] = {
                'company': x,
                'industry': i
            };
        }
    });

    // Get company predicted values.
    companyPrognoses.kpiPrognoses.forEach(function (item: KpiPrognosis) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const existingItem = seddaResult[key]['company'][0];
            const source: any = {};
            source['predicted_value'] = item.kpiPrognosisValue;
            seddaResult[key]['company'][0] = Object.assign({}, existingItem, source);
        }
    });

    // Get industry current values 50th percentile.
    industryKpiAggs.kpiAggregates.forEach(function (item: KpiAggregate) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1 && item.aggKey === '50%') {
            const existingItem = seddaResult[key]['industry'][0];
            const source: any = {};
            source['current_value'] = item.aggValue;
            seddaResult[key]['industry'][0] = Object.assign({}, existingItem, source);
        }
    });

    // Get industry predicted values.
    industryPrognoses.kpiPrognoses.forEach(function (item: KpiPrognosis) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const existingItem = seddaResult[key]['industry'][0];
            const source: any = {};
            source['predicted_value'] = item.kpiPrognosisValue;
            seddaResult[key]['industry'][0] = Object.assign({}, existingItem, source);
        }
    });

    function round(x: number, digits: number) {
        return parseFloat(x.toFixed(digits));
    }

    // Determine the domain/range for the plot.
    Object.keys(seddaResult).forEach(key => {
        const item = seddaResult[key];
        const xCo = item['company'][0]['current_value'];
        const xInd = item['industry'][0]['current_value'];
        const yCo = item['company'][0]['predicted_value'];
        const yInd = item['industry'][0]['predicted_value'];

        const xMin = Math.min(xInd, xCo, 0);
        const xMax = Math.max(xInd, xCo, 0);
        const yMin = Math.min(yInd, yCo, 0);
        const yMax = Math.max(yInd, yCo, 0);

        const minVal = Math.min(xMin, yMin, 0);
        const maxVal = Math.max(xMax, yMax);
        const minInd = Math.min(xInd, yInd);

        let xRange;
        let yRange;

        if (minVal < 0) {
            xRange = [round(minVal * 3, 2), round(maxVal * 1.5, 2)];
            yRange = [round(minVal * 3, 2), round(maxVal * 1.5, 2)];
        } else {
            xRange = [round(-minVal * 3, 2), round(maxVal * 1.5, 2)];
            yRange = [round(-minVal * 3, 2), round(maxVal * 1.5, 2)];
        }

        const existingItem = seddaResult[key];
        const source: any = {};
        source['xDomain'] = xRange;
        source['yDomain'] = yRange;
        source['xRef'] = minInd;
        source['yRef'] = minInd;
        seddaResult[key] = Object.assign({}, existingItem, source);
    });

    return seddaResult;
}