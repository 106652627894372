import * as React from 'react';
import {Link} from "react-router-dom";
import {Strings} from "../locales/strings";
import {PageEndpoints} from "../pages/pageEndpoints";

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="row footer mt-auto px-4 py-3 bg-light">
            <div className="col">&copy; {year} {Strings.COMPANY_NAME} All rights reserved.</div>
            <div className="col-auto">
                <Link to={PageEndpoints.home}>
                    Home
                </Link>
                <span className="divider">|</span>
                <Link to={PageEndpoints.publicContactUs}>
                    Contact Us
                </Link>
                <span className="divider">|</span>
                <Link to={PageEndpoints.publicTermsOfUse}>
                    Terms of Use
                </Link>
                <span className="divider">|</span>
                <Link to={PageEndpoints.publicPrivacyPolicy}>
                    Privacy Policy
                </Link>
            </div>
        </footer>
    );
}