import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {Link} from 'react-router-dom';
import {useAuth} from "../AuthProvider";
import {useCompany} from "../CompanyProvider";
import {EntitlementTypes} from "../api/entitlementTypes";
import {PageEndpoints} from '../pages/pageEndpoints';
import {Strings} from "../locales/strings";

function getCompanyName(companyList: any[], companyId: string) {

    let companyName = 'No companies yet';

    if (companyList.length > 0) {
        companyList.forEach(function (item: any) {
            if (item.company_id === companyId) {
                companyName = item.company_name;
                return;
            }
        });
    }

    return companyName;
}

export default function Menu() {
    const {getEntitlement} = useAuth();
    const {getCompanies, getCurrentCompanyId, setCurrentCompanyId} = useCompany();

    const companyList = getCompanies();
    const companyId = getCurrentCompanyId();

    const companyName = getCompanyName(companyList, companyId);

    const authorizedCompanyCount = getEntitlement(EntitlementTypes.COMPANIES_COUNT) || 1;

    const isAddCompanyAuthorized =
        authorizedCompanyCount === -1 ||
        companyList.length < authorizedCompanyCount;

    function handleSelect(event: any) {
        console.log('SELECTED: ' + event.target);
    }

    return (
        <div className="min-vh-100-sm d-flex flex-sm-column flex-row flex-nowrap align-items-center sticky-top">
            <a href="/public" className="d-block link-dark p-3 text-decoration-none logo" title="" data-bs-toggle="tooltip"
               data-bs-placement="right" data-bs-original-title="Icon-only">
                <div className="logo d-none d-sm-inline">
                    <picture>
                        <img src={process.env.PUBLIC_URL + '/images/cfo-digital-logo.png'}
                             alt="FinRatio CFOdigital logo"/>
                    </picture>
                </div>
            </a>

            <div className="ms-auto ms-sm-0 flex-shrink-1 company-name">
                <Dropdown drop={'down-centered'}>
                    <Dropdown.Toggle id="companyList" variant="white">
                        {companyName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu onSelect={handleSelect} className="w-100">
                        {
                            isAddCompanyAuthorized &&
                            <Dropdown.Item key="addCompany" as={Link} to={PageEndpoints.companyNew}>
                                Add company...
                            </Dropdown.Item>
                        }

                        {
                            !isAddCompanyAuthorized &&
                            <Dropdown.Item key="addCompanyLimit">
                                <OverlayTrigger
                                    placement="auto"
                                    delay={{show: 0, hide: 700}}
                                    overlay={(
                                        <Popover>
                                            <Popover.Body>
                                                {Strings.FEATURE_LIMIT}
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                    <div className="text-muted">
                                        Add company...
                                    </div>
                                </OverlayTrigger>
                            </Dropdown.Item>
                        }

                        {companyList.length > 1
                            ? <Dropdown.Divider/>
                            : <></>
                        }

                        {companyList.map((item: any) => (
                            item.company_id === companyId
                                ? <></>
                                : <Dropdown.Item key={item.company_id} onClick={(function () {
                                    setCurrentCompanyId(item.company_id);
                                    return false;
                                })}>
                                    {item.company_name}
                                </Dropdown.Item>
                        ))}

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <hr className="nav-divider d-none d-sm-block"/>

            <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto justify-content-between w-100 px-3 align-items-center">
                {/*<li className="nav-item mb-sm-3 w-100">*/}
                {/*    <Link to={PageEndpoints.home}>*/}
                {/*        <i className="bi-house"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span>*/}
                {/*    </Link>*/}
                {/*</li>*/}

                <li key="company-info" className="nav-item mb-sm-3 w-100 mt-4 mt-sm-0">
                    <Link to={PageEndpoints.company} data-bs-toggle="collapse">
                        <i className="bi-building"></i> <span className="ms-1 d-none d-sm-inline">Company Info</span>
                    </Link>
                </li>

                <li key="financial-reports" className="nav-item mb-sm-3 w-100 mt-4 mt-sm-0">
                    <Link to={PageEndpoints.reports}>
                        <i className="bi-bar-chart"></i> <span
                        className="ms-1 d-none d-sm-inline">Financial Reports</span>
                    </Link>
                </li>

                <li key="performance-health" className="nav-item mb-sm-3 w-100 mt-4 mt-sm-0">
                    <Link to={PageEndpoints.analysis}>
                        <i className="bi-clipboard2-pulse"></i> <span className="ms-1 d-none d-sm-inline">Performance & Prognosis</span>
                    </Link>
                </li>
            </ul>

            <div className="py-sm-4 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
                <Dropdown drop={'up-centered'}>
                    <Dropdown.Toggle id="userAccount" variant="white">
                        <picture>
                            <img className="text-primary user-icon"
                                 src={process.env.PUBLIC_URL + '/images/user-icon.png'}
                                 alt="CFOdigital logo"/>
                        </picture>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                        <Dropdown.Item key="account" as={Link} to={PageEndpoints.userAccount}>Account</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item key="sign-out" as={Link} to={PageEndpoints.userSignOut}>Sign out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}