import {Country} from "./country";

export class CountryList {
    countries: Country[] = [];

    static fromJson(json: any): CountryList {
        const obj = new CountryList();
        const countryList: Country[] = [];

        json.forEach(function (item: any) {
            countryList.push(Country.fromJson(item));
        });

        obj.countries = countryList;

        return obj;
    }
}