export class Country {
    countryCode: string = '';
    countryName: string = '';

    static fromJson(json: any): Country {
        const obj = new Country();

        obj.countryCode = json.country_code;
        obj.countryName = json.country_name;

        return obj;
    }
}