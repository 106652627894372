import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {useAuth} from "../AuthProvider";
import {postData} from "../api/postData";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";

export default function useUserVerifyRequest() {

    const {getToken} = useAuth();

    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (data: any) => {
            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const currencyResults = await postData(ApiEndpoints.userRequestEmailVerification, data, headers, signal);

            if (doExitEarly(currencyResults)) {
                return;
            }

            setStatus(PageStatus.HasSubmitted);
        };

        postForm(formData).then();

        return () => {
            controller.abort();
        };

    }, [status, getToken, formData]);

    const submitForm = (data: any) => {
        setStatus(PageStatus.IsSubmitting);
        setMessage('');
        setValidationErrors([]);

        setFormData(data);
    };

    return {
        status, message, validationErrors, submitForm, formData
    };
}