import * as React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {Strings} from "../../locales/strings";
import {PageStatus} from "../pageStatus";
import {Benchmark} from "../../api/metrics/benchmark";
import {ErrorCard} from "./ErrorCard";
import {LoadingCard} from "./LoadingCard";

const COLORS = [
    '#2c7be5',
    '#7CC674',
    '#73C5C5',
    '#8481DD',
    '#F6D173',
    '#EF9234',
    '#a2a2a2'
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function Chart({data}: { data: Benchmark[] }) {

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart width={400} height={400}>
                    <Pie data={data}
                         dataKey="benchmarkValue"
                         labelLine={false}
                         label={renderCustomizedLabel}
                         nameKey="benchmarkName"
                         startAngle={450}
                         endAngle={90}
                         cx="50%"
                         cy="50%">
                        {
                            data?.map((item: Benchmark, index: number) => (
                                <Cell key={item.benchmarkKey} fill={COLORS[index % COLORS.length]}/>
                            ))
                        }
                    </Pie>
                    <Legend
                        layout="horizontal"
                        align="center"
                        verticalAlign="bottom"
                    />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}

export default function BalanceSheetIndustryChart(
    {status, title, data}: {
        status: PageStatus,
        title: string,
        data: Benchmark[] | null
    }) {

    let template = <></>;

    if (status === PageStatus.IsReady || status === PageStatus.IsSubmitting) {
        template = <LoadingCard description1={Strings.CHART_GENERATING_ANALYSIS}/>;
    } else if (status === PageStatus.HasSubmitted && data !== null) {
        template = <Chart data={data}/>;
    } else if (status === PageStatus.HasError) {
        template = <ErrorCard description2="Unable to load chart."/>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}