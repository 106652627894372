export class ProductPrice {
    priceId: string = '';
    currencyCode: string = '';
    amount: number = 0;
    recurringInterval: string = '';

    static fromJson(json: any): ProductPrice {
        const obj = new ProductPrice();

        obj.priceId = json.price_id;
        obj.currencyCode = json.currency_code;
        obj.amount = json.amount;
        obj.recurringInterval = json.recurring_interval;

        return obj;
    }
}