export class Company {
    companyId: string = '';
    companyName: string = '';
    countryCode: string = '';
    currencyCode: string = '';
    sectorKey: string = '';
    sectorName: string = '';
    subsectorKey: string = '';
    subsectorName: string = '';

    static fromJson(json: any): Company {
        const obj = new Company();

        obj.companyId = json.company_id;
        obj.companyName = json.company_name;
        obj.countryCode = json.country_code;
        obj.currencyCode = json.currency_code;
        obj.sectorKey = json.sector_key;
        obj.sectorName = json.sector_name;
        obj.subsectorKey = json.subsector_key;
        obj.subsectorName = json.subsector_name;

        return obj;
    }

    toJson() {
        return {
            "company_id": this.companyId,
            "company_name": this.companyName,
            "country_code": this.countryCode,
            "currency_code": this.currencyCode,
            "sector_key": this.sectorKey,
            "subsector_key": this.subsectorKey
        };
    }
}