import * as React from 'react';

export default function UserAccountHeader() {

    return (
        <div className="header">
            <div className="row align-items-end d-flex align-items-center">
                <div className="col">
                    <h6 className="header-pretitle">
                        Overview
                    </h6>
                    <h1 className="header-title">
                        Account
                    </h1>
                </div>
            </div>
        </div>
    );
}