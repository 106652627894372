import {yupResolver} from "@hookform/resolvers/yup";
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {ApiEndpoints} from "../../api/apiEndpoints";
import FormError from "../../components/FormError";
import FormSuccess from "../../components/FormSuccess";
import Logo from "../../components/Logo";
import SubmitButton from "../../components/SubmitButton";
import {PageStatus} from "../pageStatus";
import {PageTitles} from '../../locales/pageTitles';
import usePostData from "../../hooks/usePostData";
import {publicContactUsValidationSchema} from "../../api/user/userValidationSchema";

export default function ContactUs() {
    const {status, message, submitForm} = usePostData();

    useEffect(() => {
        document.title = PageTitles.publicContactUs;
    }, []);

    const {
        register,
        reset,
        handleSubmit,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(publicContactUsValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    const onSubmitHandler = async (data: any) => {
        submitForm(ApiEndpoints.userContactUs, data, true);
    };

    useEffect(() => {
        if (status === PageStatus.HasSubmitted) {
            reset();
        }
    }, [status, message, reset]);

    return (
        <div className="contact">
            <div className="bg-light py-5">
                <div className="pb-1">
                    <Logo/>
                </div>
                <h1 className="mb-4">Contact Us</h1>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-lg-5">
                        <p className="header-subtitle mb-5">Give us a few details and we&apos;ll be in touch soon.</p>
                        <FormError status={status} message={message}/>
                        <FormSuccess status={status} message={message}></FormSuccess>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <fieldset id="me" disabled={status === PageStatus.IsSubmitting}>
                                <div className="form-floating">
                                    <input {...register('name')} type="text" className="form-control"
                                           id="floatingName" placeholder="John Example"/>
                                    <label htmlFor="floatingName">Name</label>
                                    <small className="text-danger">
                                        {errors?.name?.message?.toString()}
                                    </small>
                                </div>

                                <div className="form-floating">
                                    <input {...register('email_address')} type="text" className="form-control"
                                           id="floatingEmail" placeholder="name@example.com"/>
                                    <label htmlFor="floatingEmail">Email address</label>
                                    <small className="text-danger">
                                        {errors?.email_address?.message?.toString()}
                                    </small>
                                </div>

                                <div className="form-floating">
                                    <textarea {...register('message')}
                                              className="form-control"
                                              id="floatingMessage"
                                              placeholder="Type your message here"
                                              rows={5}
                                    />
                                    <label htmlFor="floatingMessage">Message</label>
                                    <small className="text-danger">
                                        {errors?.message?.message?.toString()}
                                    </small>
                                </div>

                                <div className="mt-5">
                                    <SubmitButton
                                        isFormReady={isFormReady}
                                        featureStatus={status}
                                        text="Submit"
                                        isBlock={true}
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}