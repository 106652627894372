import * as React from "react";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useCompany} from "../../CompanyProvider";
import {PageTitles} from "../../locales/pageTitles";
import {ReportAdd} from "./ReportAdd";
import {ReportList} from "./ReportList";

export function ReportRoot() {
    const location = useLocation();
    const {getCurrentCompanyId} = useCompany();
    const companyId = getCurrentCompanyId();

    useEffect(() => {
        document.title = PageTitles.reports;
    }, []);

    let template;

    if (location.pathname !== '/add-report') {
        template = (<ReportList key={companyId} companyId={companyId}/>);
    } else {
        template = (<ReportAdd key={companyId} companyId={companyId}/>);
    }

    // Use key to ensure tree is different for each company.
    return (
        <div className="p-4 content reports" id="reportRoot">
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-xl-10 col-xxl-8">
                    {template}
                </div>
            </div>
        </div>
    );
}