import {Currency} from "./currency";

export class CurrencyList {
    currencies: Currency[] = [];

    static fromJson(json: any): CurrencyList {
        const obj = new CurrencyList();
        const currencyList: Currency[] = [];

        json.forEach(function (item: any) {
            currencyList.push(Currency.fromJson(item));
        });

        obj.currencies = currencyList;

        return obj;
    }
}