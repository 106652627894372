import * as React from "react";
import {useState} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {useAuth} from "../../AuthProvider";
import {Strings} from "../../locales/strings";
import {formatDate} from "../../utils/dateUtils";
import {toTitleCase} from "../../utils/stringUtils";
import FormError from "../../components/FormError";
import FormSuccess from "../../components/FormSuccess";
import {PageStatus} from "../pageStatus";
import usePostData from "../../hooks/usePostData";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

export function UserAccountEdit() {
    const {getUser} = useAuth();
    const {status: cancelStatus, message: cancelMessage, submitForm} = usePostData();
    const [showCancelModal, setShowCancelModal] = useState(false);

    const handlePromptCancelSubscription = () => {
        setShowCancelModal(true);
    };

    const handleContinueCancelSubscription = () => {
        submitForm(ApiEndpoints.userCancelSubscription, {}, true, true);
    };

    const user = getUser();
    const disableForm =
        cancelStatus === PageStatus.IsLoading
        || cancelStatus === PageStatus.IsSubmitting
        || (user !== null && user.subscription.cancelAtPeriodEnd);
    const cancelModalButton = Strings.USER_SUBSCRIPTION_CANCEL_BUTTON;

    return (
        <>
            <CancelSubscriptionModal
                show={showCancelModal}
                setShow={setShowCancelModal}
                buttonText={cancelModalButton}
                doConfirmation={handleContinueCancelSubscription}
            />
            <FormError status={cancelStatus} message={cancelMessage}/>
            <FormSuccess status={cancelStatus} message={cancelMessage}/>
            <Tabs
                defaultActiveKey="subscription"
                className="mb-3"
            >
                <Tab eventKey="subscription" title="Subscription">
                    <div className="row">
                        <div className="col">
                            {
                                user && user.subscription.cancelAtPeriodEnd &&
                                <div className="alert alert-danger">
                                    <div className="row">
                                        <div className="col-auto">
                                            <i className="bi bi-info-circle pe-2"></i>
                                        </div>
                                        <div className="col ps-0">
                                            Your subscription was canceled. You will no longer have access after the
                                            current period ends.
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="text-muted">Subscription status</h4>
                                    <div className="text-emphasis">
                                        {user && toTitleCase(user.subscription.subscriptionStatus)}

                                        {
                                            user && user.subscription.cancelAtPeriodEnd &&
                                            " (Canceled)"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="text-muted">Current period end</h4>
                                    <div className="text-emphasis">
                                        {user && formatDate(user.subscription.currentPeriodEndDate)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Cancel your subscription</h4>
                                            <div className="small text-muted">
                                                Please note, cancelling your subscription is a permanent action.
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn btn-danger"
                                                disabled={disableForm}
                                                onClick={handlePromptCancelSubscription}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*<div className="row">*/}
                    {/*    <div className="col">*/}
                    {/*        <h4>Cancel your subscription</h4>*/}
                    {/*        <div className="small text-muted">*/}
                    {/*            Please note, cancelling your subscription is a permanent action.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-auto">*/}
                    {/*        <button*/}
                    {/*            className="btn btn-danger"*/}
                    {/*            disabled={disableForm}*/}
                    {/*            onClick={handlePromptCancelSubscription}*/}
                    {/*        >*/}
                    {/*            Cancel*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </Tab>
            </Tabs>
        </>
    );
}
