export enum KpiKeys {
    ADI = 'adi',
    ADP = 'adp',
    AP_TURNOVER = 'ap_turnover',
    AR_TURNOVER = 'ar_turnover',
    BASIC_EARNING_POWER = 'basic_earning_power',
    CASH_CYCLE = 'cash_cycle',
    CASH_RATIO = 'cash_ratio',
    CLCC = 'clcc',
    CURRENT_RATIO = 'current_ratio',
    DEBT_RATIO = 'debt_ratio',
    DE_RATIO = 'de_ratio',
    DSO = 'dso',
    FIXED_ASSET_TURNOVER = 'fixed_asset_turnover',
    IAICOC = 'iaicoc',
    INVENTORY_TURNOVER = 'inventory_turnover',
    LONG_TERM_DEBT_RATIO = 'long_term_debt_ratio',
    LYCA = 'lyca',
    NCG = 'ncg',
    NET_MARGIN = 'net_margin',
    OCG = 'ocg',
    OCS = 'ocs',
    OPERATING_MARGIN = 'operating_margin',
    PPE_TURNOVER = 'ppe_turnover',
    QOFFUR = 'qoffur',
    QPT = 'qpt',
    QUICK_RATIO = 'quick_ratio',
    ROA = 'roa',
    ROA2BOND = 'roa2bond',
    ROCE = 'roce',
    ROE = 'roe',
    TANGIBLE_ASSET_TURNOVER = 'tangible_asset_turnover',
    TOTAL_ASSET_TURNOVER = 'total_asset_turnover',
    WC_TURNOVER = 'wc_turnover',
}