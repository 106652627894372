import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from './AuthProvider';
import {PageEndpoints} from './pages/pageEndpoints';

function RouteGuard({children}: any) {
    const location = useLocation();
    const {isAuth, getUser} = useAuth();

    if (!isAuth()) {
        return <Navigate to={PageEndpoints.userSignOut} replace={true}/>;
    }

    const user = getUser();

    if (user === null) {
        return <Navigate to={PageEndpoints.userSignOut} replace={true}/>;
    }

    if (!user.subscription.isActive) {
        console.warn('Subscription does not exist or is not active in the API.');
        console.log(JSON.stringify(user));

        return <Navigate to={PageEndpoints.userSubscription} state={location.state}/>;
    }

    // IMPORTANT: There is no route guard on public routes.
    // TODO: Testing subscriptions and plans for one user.
    // const testId = 'ae0161f9-c425-46de-9750-5cdae888a68e';
    // const testFree = 'f2cfed62-36bd-4bb1-9d50-49968eccfac1';
    // const testBasic = '662b55c5-09ab-4640-b533-359547f00655';
    // const testNoPlan = '1b39b5fc-33f5-4626-a020-a21b1339cc4e';
    //
    // if (user.userId === testId || user.userId === testFree || user.userId === testBasic || user.userId === testNoPlan) {
    //     console.warn('Testing subscriptions and plans for user.');
    //     console.log('Route guard USER: ' + JSON.stringify(getUser()));
    //
    //     if (!user.subscription.isActive) {
    //         console.warn('Subscription does not exist or is not active in the API.');
    //         console.log(JSON.stringify(user));
    //
    //         return <Navigate to={PageEndpoints.userSubscription} state={location.state}/>;
    //     }
    // }

    return children;
}

export default RouteGuard;