import * as Yup from "yup";

export const userValidationSchema = Yup.object().shape({
    email_address: Yup.string()
        .required('Email is required.')
        .max(255, 'Email must not exceed 255 characters.')
        .email('Email is not valid.'),
    password: Yup.string()
        .required('Password is required.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
            'Password must be at least 8 characters, including one number, one uppercase and lowercase letter, and one special character.'
        )
        .max(50, 'Password must not exceed 50 characters.')
});

export const userEmailChangeValidationSchema = Yup.object().shape({
    email_address: Yup.string()
        .required('Email is required.')
        .max(255, 'Email must not exceed 255 characters.')
        .email('Email is not valid.'),
    verification_code: Yup.string()
        .required('Verification code is required.')
        .min(6, 'Verification code must be 6 characters.')
        .max(6, 'Verification code must be 6 characters.'),
    password: Yup.string()
        .required('Password is required.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
            'Password must be at least 8 characters, including one number, one uppercase and lowercase letter, and one special character.'
        )
        .max(50, 'Password must not exceed 50 characters.'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords do not match.')
});

export const userVerificationValidationSchema = Yup.object().shape({
    email_address: Yup.string()
        .required('Email is required.')
        .max(255, 'Email must not exceed 255 characters.')
        .email('Email is not valid.'),
    verification_code: Yup.string()
        .required('Verification code is required.')
        .min(6, 'Verification code must be 6 characters.')
        .max(6, 'Verification code must be 6 characters.')
});

export const userEmailValidationSchema = Yup.object().shape({
    email_address: Yup.string()
        .required('Email is required.')
        .max(255, 'Email must not exceed 255 characters.')
        .email('Email is not valid.')
});

export const publicContactUsValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .max(100, 'Name must not exceed 100 characters.'),
    email_address: Yup.string()
        .required('Email is required.')
        .max(255, 'Email must not exceed 255 characters.')
        .email('Email is not valid.'),
    message: Yup.string()
        .max(1000, 'Message must not exceed 1000 characters.')
});
