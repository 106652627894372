import * as React from 'react';
import {useEffect} from 'react';
import {Strings} from "../../locales/strings";
import Logo from "../../components/Logo";
import {PageTitles} from '../../locales/pageTitles';

export default function PrivacyPolicy() {

    useEffect(() => {
        document.title = PageTitles.publicPrivacy;
    }, []);

    return (
        <div className="privacy">
            <div className="bg-light py-5">
                <div className="pb-1">
                    <Logo/>
                </div>
                <h1 className="mb-4">Privacy Policy</h1>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-lg-8">
                        <h2>Effective: October 1, 2024</h2>
                        <p className="mt-3 mb-3">
                            {Strings.COMPANY_NAME} (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;) operates
                            CFOdigital.ai
                            (&quot;the platform&quot;). This Privacy Policy outlines how we collect, use, disclose, and
                            protect your personal and financial data when you use CFOdigital.ai. By accessing the
                            platform,
                            you agree to this Privacy Policy.
                        </p>

                        <ol className="outer">
                            <li>
                                <p><strong>Information We Collect</strong></p>
                                <p>We collect the following types of information:</p>
                                <p>
                                    <ul>
                                        <li>
                                            <p><strong>Personal Information</strong></p>
                                            <p>
                                                When you create an account, we collect personal information, including
                                                but
                                                not limited to:
                                                <ul>
                                                    <li>Your name</li>
                                                    <li>Email address</li>
                                                    <li>Contact information</li>
                                                    <li>Company information</li>
                                                </ul>
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Financial Information</strong></p>
                                            <p>
                                                We collect the financial data you submit to the platform, which may
                                                include:
                                                <ul>
                                                    <li>Income statements</li>
                                                    <li>Balance sheets</li>
                                                    <li>Cash flow statements</li>
                                                    <li>Other financial data necessary for generating performance
                                                        reports
                                                    </li>
                                                </ul>
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Usage Data</strong></p>
                                            <p>
                                                We automatically collect information about how you interact with
                                                CFOdigital.ai, including:
                                                <ul>
                                                    <li>IP address</li>
                                                    <li>Browser type</li>
                                                    <li>Operating system</li>
                                                    <li>Access times and dates</li>
                                                    <li>Pages viewed</li>
                                                </ul>
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Cookies</strong></p>
                                            <p>
                                                We use cookies and similar technologies to track user activity and
                                                preferences on the platform. You can manage cookie settings through your
                                                browser, but disabling cookies may limit certain functionalities of the
                                                platform.
                                            </p>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p><strong>How We Use Your Information</strong></p>
                                <p>We use the information we collect to:</p>
                                <p>
                                    <ul>
                                        <li>Provide and improve our services</li>
                                        <li>Generate financial performance analysis reports</li>
                                        <li>Communicate with you regarding your account or service updates</li>
                                        <li>Comply with legal obligations</li>
                                        <li>Protect the security and integrity of our platform</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p><strong>Sharing of Your Information</strong></p>
                                <p>
                                    We do not sell or rent your personal or financial information to third parties.
                                    However,
                                    we may share your information in the following circumstances:
                                </p>
                                <p>
                                    <ul>
                                        <li>
                                            <p><strong>Service Providers</strong></p>
                                            <p>
                                                We may share your information with third-party service providers who
                                                assist
                                                us in operating the platform, conducting business, or providing services
                                                to
                                                you (e.g., cloud storage providers, customer support).
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Legal Compliance</strong></p>
                                            <p>
                                                We may disclose your information if required by law, such as to comply
                                                with
                                                a subpoena, court order, or other legal process, or to protect our
                                                rights
                                                and property.
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Business Transfers</strong></p>
                                            <p>
                                                In the event of a merger, acquisition, or sale of all or a portion of
                                                our
                                                assets, your information may be transferred as part of the business
                                                transaction.
                                            </p>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p><strong>Data Security</strong></p>
                                <p>
                                    We implement reasonable security measures to protect your personal and financial
                                    information from unauthorized access, disclosure, alteration, or destruction.
                                    However,
                                    no data transmission over the internet or electronic storage system is completely
                                    secure. While we strive to protect your information, we cannot guarantee its
                                    absolute
                                    security.
                                </p>
                            </li>
                            <li>
                                <p><strong>Data Retention</strong></p>
                                <p>
                                    We retain your personal and financial data for as long as your account is active or
                                    as
                                    needed to provide you with our services. We may also retain your information to
                                    comply
                                    with legal obligations, resolve disputes, and enforce our agreements.
                                </p>
                            </li>
                            <li>
                                <p><strong>Your Rights and Choices</strong></p>
                                <p>
                                    You have the following rights regarding your information:
                                </p>
                                <p>
                                    <ul>
                                        <li>
                                            <p><strong>Access and Update:</strong></p>
                                            <p>
                                                You can access, update, or correct your personal information by logging
                                                into
                                                your account.
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Data Deletion</strong></p>
                                            <p>
                                                You may request the deletion of your personal or financial information.
                                                However, we may retain certain data to comply with legal obligations or
                                                legitimate business purposes.
                                            </p>
                                        </li>
                                        <li>
                                            <p><strong>Opt-out of Marketing</strong></p>
                                            <p>
                                                You may opt out of receiving marketing emails by following the
                                                unsubscribe
                                                instructions in those emails.
                                            </p>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p><strong>Children&apos;s Privacy</strong></p>
                                <p>
                                    CFOdigital.ai is not intended for use by individuals under the age of 18. We do not
                                    knowingly collect personal information from children under 18. If we learn that we
                                    have
                                    collected information from a child under 18, we will delete it promptly.
                                </p>
                            </li>
                            <li>
                                <p><strong>Changes to This Privacy Policy</strong></p>
                                <p>
                                    We reserve the right to update this Privacy Policy at any time. We will notify you
                                    of
                                    any significant changes by posting the updated policy on CFOdigital.ai and updating
                                    the &quot;Effective Date.&quot; Your continued use of the platform after any changes
                                    constitutes acceptance of the revised policy.
                                </p>
                            </li>
                            <li>
                                <p><strong>Contact Us</strong></p>
                                <p>
                                    If you have any questions or concerns about this Privacy Policy, please contact us
                                    at
                                    support@finratio.ai.
                                </p>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}