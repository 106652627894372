import {BenchmarkKey} from "./benchmarkKey";

export class Benchmark implements BenchmarkKey {
    benchmarkKey: string = '';
    benchmarkName: string = '';
    benchmarkValue: number | null = null;
    accountCategoryKey: string = '';

    static fromJson(json: any): Benchmark {
        const obj = new Benchmark();

        obj.benchmarkKey = json.benchmark_key;
        obj.benchmarkName = json.benchmark_name;
        obj.benchmarkValue = json.benchmark_value;
        obj.accountCategoryKey = json.account_category_key;

        // TODO: Add account category name.

        return obj;
    }
}