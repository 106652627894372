import {useEffect, useState} from "react";
import {useAuth} from "../AuthProvider";
import {postData} from "../api/postData";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";

export default function useMultiPostData(urlCount: number) {
    const {getToken} = useAuth();

    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    const [urls, setUrls] = useState<string[]>([]);
    const [data, setData] = useState<any[]>([]);

    // Returns a list of lists. If error occurs, will return empty list in that position.
    const initResults: any[] = [];

    for (let i = 0; i < urlCount; i++) {
        initResults.push([]);
    }

    const [results, setResults] = useState<any[]>(initResults);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (urls: string[], data: any[]) => {
            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);
            const promises: any[] = [];

            urls.forEach(function (url: string, index: number) {
                promises.push(postData(url, data[index], headers, signal));
            });

            const promiseResults = await Promise.allSettled(promises);

            let hasError = false;
            const resultList: any[] = [];

            promiseResults.forEach(function (result: any) {
                if (result.status === 'fulfilled') {
                    if (doExitEarly(result.value)) {
                        hasError = true;
                        resultList.push([]);
                    } else {
                        resultList.push(result.value.results);
                    }
                } else {
                    hasError = true;
                    resultList.push([]);
                    console.warn('Un-fulfilled promise: ' + JSON.stringify(result));
                }
            });

            setResults(resultList);

            // Keep error status.
            if (hasError) {
                return;
            }

            setStatus(PageStatus.HasSubmitted);
        };

        postForm(urls, data).then();

        return () => {
            controller.abort();
        };

    }, [status, getToken, urls, data]);

    const submitPost = (urls: string[], data: any[]) => {

        setStatus(PageStatus.IsSubmitting);
        setUrls(urls);
        setData(data);
        setMessage('');
        setValidationErrors([]);

        // For convenience, returns an empty list of lists while processing.
        const initResults: any[] = [];

        urls.forEach(function () {
            initResults.push([]);
        });

        setResults(initResults);
    };

    return {
        status, message, validationErrors, submitPost, results
    };
}