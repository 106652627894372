import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function CancelSubscriptionModal(
    {show, setShow, buttonText, doConfirmation}: {
        show: boolean,
        setShow: any,
        buttonText: string,
        doConfirmation: any
    }) {

    const handleDisregard = () => {
        setShow(false);
    };

    const handleCancelSubscription = () => {
        setShow(false);
        doConfirmation();
    };

    return (
        <Modal show={show} onHide={handleDisregard}>
            <Modal.Body>
                <h3>Are you sure?</h3>
                <p>You will have access until your subscription period end date.</p>
                <p>After that, you will no longer have access to your account, reports, or invoices.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleDisregard}>
                    Not now
                </Button>
                <Button variant="danger" onClick={handleCancelSubscription}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
