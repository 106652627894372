import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {formatPercentage} from "../../utils/numberUtils";
import {PageEndpoints} from "../pageEndpoints";
import Logo from "../../components/Logo";
import {PageStatus} from "../pageStatus";
import {PageTitles} from '../../locales/pageTitles';
import usePostData from "../../hooks/usePostData";
import {SubscriptionInterval} from "../../api/products/subscriptionInterval";
import {ProductList} from "../../api/products/productList";
import ProductCard from "./ProductCard";

export default function Pricing() {
    const navigate = useNavigate();
    const [recurringInterval, setRecurringInterval] = useState(SubscriptionInterval.MONTH);
    const {status: productListStatus, submitForm, results: productListResults} = usePostData();
    const [productList, setProductList] = useState<ProductList | null>(null);

    useEffect(() => {
        document.title = PageTitles.publicPricing;
    }, []);

    useEffect(() => {
        if (productList === null && productListStatus === PageStatus.IsReady) {
            submitForm(ApiEndpoints.productsList, {});
        }
    }, [productListStatus, productList, submitForm]);

    useEffect(() => {
        if (productListResults !== null) {
            const productList = ProductList.fromJson(productListResults);
            setProductList(productList);
        }
    }, [productListResults]);

    const handlePlanPeriodChange = (event: any) => {
        setRecurringInterval(event.target.value);
    };

    const handleOnSelectPlan = (productId: string) => {
        navigate(PageEndpoints.userSignIn, {state: {'productId': productId}});
    };

    const showMonthly = recurringInterval === SubscriptionInterval.MONTH;

    return (
        <div className="pricing">
            <div className="bg-light py-5">
                <div className="pb-1">
                    <Logo/>
                </div>
                <h1 className="mb-4">Plans & Pricing</h1>
                <p className="text-muted text-center mb-5">
                    We have plans and prices that fit your business perfectly.
                </p>
                <div className="row justify-content-center mb-4">
                    <div className="col text-center plan-period mb-4">
                        <div className="btn-group" role="group">
                            <input type="radio" className="btn-check" name="plan_period"
                                   id="plan-period-month"
                                   autoComplete="off"
                                   value={SubscriptionInterval.MONTH}
                                   onChange={handlePlanPeriodChange}
                                   checked={recurringInterval === SubscriptionInterval.MONTH}/>
                            <label className="btn btn-outline-light period me-1"
                                   htmlFor="plan-period-month">
                                Monthly
                            </label>

                            <input type="radio" className="btn-check" name="plan_period"
                                   id="plan-period-annual"
                                   autoComplete="off"
                                   value={SubscriptionInterval.YEAR}
                                   onChange={handlePlanPeriodChange}
                                   checked={recurringInterval === SubscriptionInterval.YEAR}/>
                            <label className="btn btn-outline-light period ms-1"
                                   htmlFor="plan-period-annual">
                                Annually
                                <span className="badge badge-outline-primary ms-2">
                                   up to {formatPercentage(.2, 0)} off
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-3 col-xxl-2">
                        {
                            productList && <ProductCard
                                product={productList.products[0]}
                                showMonthly={showMonthly}
                                selectedProductId=""
                                onSelect={handleOnSelectPlan}
                            />
                        }
                    </div>
                    <div className="col-12 mt-4 col-md-6 mt-md-0 col-lg-3 col-xxl-2">
                        {
                            productList && <ProductCard
                                product={productList.products[1]}
                                showMonthly={showMonthly}
                                selectedProductId=""
                                onSelect={handleOnSelectPlan}
                            />
                        }
                    </div>
                    <div className="col-12 mt-4 col-md-6 col-lg-3 mt-lg-0 col-xxl-2">
                        {
                            productList && <ProductCard
                                product={productList.products[2]}
                                showMonthly={showMonthly}
                                selectedProductId=""
                                onSelect={handleOnSelectPlan}
                            />
                        }
                    </div>
                    <div className="col-12 mt-4 col-md-6 col-lg-3 mt-lg-0 col-xxl-2">
                        {
                            productList && <ProductCard
                                product={productList.products[3]}
                                showMonthly={showMonthly}
                                selectedProductId=""
                                onSelect={handleOnSelectPlan}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}