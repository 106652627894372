import * as React from 'react';
import {formatCurrency} from "../../utils/numberUtils";

export default function StatementLine({accountKey, accountName, amount, currency}: any) {

    return (
        <tr key={accountKey}>
            <td>{accountName}</td>
            <td className="text-end">{formatCurrency(amount, 0, currency)}</td>
        </tr>
    );
}
