import * as React from "react";
import {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {PageEndpoints} from '../pageEndpoints';

export default function NotFoundRoot() {
    const navigate = useNavigate();

    // Navigate to not found page without showing menu.
    useEffect(() => {
        navigate(PageEndpoints.notFound);
    });

    return (
        <></>
    );
}