import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, StripeElementsOptions} from "@stripe/stripe-js";
import * as React from 'react';
import {useEffect} from 'react';
import {PageTitles} from '../../locales/pageTitles';
import UserSubscribeForm from "./UserSubscribeForm";

// eslint-disable-next-line no-undef
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';

// Call outside component to avoid creating on every render.
const stripePromise = loadStripe(stripeKey);

export default function UserSubscribe() {

    useEffect(() => {
        document.title = PageTitles.userSubscribe;
    }, []);

    const options: StripeElementsOptions = {
        mode: 'subscription',
        currency: 'usd',
        amount: 0
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <UserSubscribeForm/>
        </Elements>
    );
}