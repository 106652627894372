export const formatCurrency = function (
    val: number,
    fractionDigits = 2,
    currency: string = 'USD') {

    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
        currencySign: "accounting",
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
    }).format(val);
};

export const formatPercentage = function (
    val: number,
    fractionDigits = 2) {

    return Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
    }).format(val);
};

export function parseNumber(val: string) {
    val = val.toString();

    // If value contains a dash, move it to the front.
    if (val.indexOf('-') > 0) {
        val = val
            .replace(/[^0-9.-]/g, '')       // remove chars except number, hyphen, point.
            .replace(/(\..*)\./g, '$1')     // remove multiple points.
            .replace(/(?!^)-/g, '')         // remove middle hyphen.
            .replace(/^0+(\d)/gm, '$1');    // remove multiple leading zeros.
        val = "-" + val;
    }

    val = val
        .replace(/[^0-9.-]/g, '')       // remove chars except number, hyphen, point.
        .replace(/(\..*)\./g, '$1')     // remove multiple points.
        .replace(/(?!^)-/g, '')         // remove middle hyphen.
        .replace(/^0+(\d)/gm, '$1');    // remove multiple leading zeros.

    if (val === '-') {
        return 0;
    }

    return Number(val);
}

export function round(val: number | null | undefined, digits: number) {
    if (val === null) {
        console.warn('Value is null.');
        return 0;
    }

    if (val === undefined) {
        console.warn('Value is undefined.');
        return 0;
    }

    return parseFloat(val.toFixed(digits));
}