export class ProductFeature {
    featureId: string = '';
    featureName: string = '';
    featureValue: any = null;
    categoryName: string = '';

    static fromJson(json: any): ProductFeature {
        const obj = new ProductFeature();

        obj.featureId = json.feature_id;
        obj.featureName = json.feature_name;
        obj.featureValue = json.feature_value;
        obj.categoryName = json.category_name;

        return obj;
    }
}