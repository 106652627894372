import * as React from "react";
import {useEffect} from "react";
import Logo from "../../components/Logo";
import {PageTitles} from "../../locales/pageTitles";

export default function Faqs() {

    useEffect(() => {
        document.title = PageTitles.publicFaqs;
    }, []);

    return (
        <div className="faqs">
            <div className="bg-light py-5">
                <div className="pb-1">
                    <Logo/>
                </div>
                <h1 className="mb-5">Frequently Asked Questions</h1>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-xl-8">
                        <h2>What is Financial Prognosis?</h2>
                        <ul>
                            <li>
                                It is a statistical calculation of the expected value of financial ratio (KPI),
                                providing the most likely future value calculated based on a significant and large
                                sample of companies globally.
                            </li>
                            <li>
                                The probabilities of an outcome being within a certain range are driven by the
                                underlying PDF (probability density function).
                            </li>
                        </ul>
                        <h2>What is the business purpose of Financial Prognosis?</h2>
                        <ul>
                            <li>
                                To provide a realistic and statistically grounded outlook of the company’s financial
                                future.
                            </li>
                            <li>
                                To identify potential financial challenges and opportunities.
                            </li>
                            <li>
                                To inform risk management strategies.
                            </li>
                            <li>
                                To support informed decision-making by providing a data-driven view of likely future
                                outcomes.
                            </li>
                        </ul>
                        <h2>How is Financial Prognosis calculated</h2>
                        <ul>
                            <li>
                                We use proprietary neural networks (deep learning) trained with standardized financial
                                ratios to learn the statistics of the financial data of small, medium and large
                                enterprises across all industries except banks and financial institutions.
                            </li>
                            <li>
                                We augment the set of financial ratios typically used in financial statement analysis
                                with 9 proprietary engineered KPIs aimed at capturing (1) quality of operating,
                                investing and financing cashflows, and (2) the fluid, always changing macroeconomic
                                environment in which the companies must perform.
                            </li>
                            <li>
                                In our research, the use of such proprietary KPIs proved to be very important in driving
                                higher levels of accuracy in KPI predictions compared to models that use standard
                                financial ratios only.
                            </li>
                        </ul>
                        <h2>How should Financial Prognosis be interpreted?</h2>
                        <ul>
                            <li>
                                Behind a prognosis value there is a PDF (probability density function) curve. The
                                relative and absolute differences between the prognosis value and current value indicate
                                whether there is a high, moderate or low probability of the company performing better in
                                the next business cycle.
                            </li>
                        </ul>
                        <h2>How accurate are Financial Prognosis predictions?</h2>
                        <ul>
                            <li>
                                Our initial research using neural networks for financial predictions (1st Generation AI
                                models) showed better performance (lower MSE error) than multivariate regressor and
                                ensemble models: 10 – 30% lower error rates. Research is available at
                                https://arxiv.org/abs/2311.05840.
                            </li>
                            <li>
                                In 2024, FinRatio introduced its 2nd Generation AI models, which further improve model
                                performance reducing error rates by up to 50%+.
                            </li>
                        </ul>
                        <h2>How is this approach different than financial analysis methods currently used?</h2>
                        <ul>
                            <li>
                                Financial analysts rely mostly on two methods for performance analysis based on
                                financial ratios: (1) time series data (forecasting) and (2) multivariate ML analysis –
                                regressors or classifiers-, mostly linear regressors.
                            </li>
                            <li>
                                The problem that we observe is that these methods are heavily anchored on implicit
                                linearity assumptions (over time or vs. explaining variables). However, business
                                decisions are not linear. The complexity of a typical business decision undertaken by a
                                knowledgeable CFO demands a new approach, one that captures the non-linearity of a
                                financial outcome (e.g., improved net margin) to a wide set of financial inputs.
                            </li>
                            <li>
                                FinRatio’s breakthrough use of neural networks has proven the feasibility to leverage AI
                                / deep learning to develop models that more closely mirror how a CFO thinks.
                            </li>
                            <li>
                                In our research, an ‘apples to apples’ comparison of best multivariate linear regressor
                                vs. FNN models showed the latter outperforming the former by 60%+ across six different
                                financial performance prediction tasks.
                            </li>
                        </ul>
                        <h2>How should a CFO, corporate treasurer or financial analyst use CFOdigital?</h2>
                        <ul>
                            <li>
                                CFOdigital is an AI-powered analytical platform that allows users to assess potential
                                root causes of financial under-performance at deeper levels than standard financial
                                ratio
                                analysis.
                            </li>
                        </ul>
                        <h2>What is Company Competitiveness Prognosis scoring (CCP)?</h2>
                        <ul>
                            <li>
                                It is a proprietary score in the range 0-100, which uses AI to produce a financial
                                prognosis for both company and industry and score the relative expected performance of
                                the company vis-à-vis the industry.
                            </li>
                            <li>
                                FinRatio produces 6 CCP scores to assess the company competitiveness along 6 key
                                performance indicators to provide a comprehensive diagnostic along key financial
                                management areas of profitability, liquidity and efficiency: Net Margin, Operating
                                Margin, ROA, ROE, Cash Ratio and Operating Cash Generation (quality of operating
                                cashflows).
                            </li>
                        </ul>
                        <h2>What are FinRatio’s Cashflow Quality ratios (NCG, OCG, CLCC, OCS, QPT, QOFFUR)?</h2>
                        <table className="table table-striped table-hover">
                            <tbody>
                            <tr>
                                <td>Net Cash Generation (NCG)</td>
                                <td>Net Cashflow / Cash</td>
                                <td>
                                    Measures how much of the outstanding cash at the end of the period was generated
                                    from either operations, financing or investing activities during the period. Values
                                    greater than 1 indicate that cash on balance has increased during the period,
                                    although the metric does not differentiate source of cash (operations, investing or
                                    financing).
                                </td>
                            </tr>
                            <tr>
                                <td>Operating Cash Generation (OCG)</td>
                                <td>Operating Cashflow / Cash</td>
                                <td>
                                    Measures how much of the outstanding cash at the end of the period was generated
                                    from operations during the period. OCG values greater than +1 measure how many times
                                    the company produces cash on Balance Sheet during the period. OCG values between 0
                                    and 1 indicate that company produces net operating cashflows, but other cash in
                                    balance sheet is coming from investing and / or financing activities. Negative OCG
                                    values indicate that operations are net cash negative, and cash on Balance Sheet
                                    comes strictly from investing and / or financing activities, representing a riskier
                                    business performance. OCG metric is best when considered in tandem with other
                                    financial ratios such as Current Ratio since a low cash on balance yields a higher
                                    OCG value.
                                </td>
                            </tr>
                            <tr>
                                <td>Current Liabilities<br/>Cash Coverage (CLCC)</td>
                                <td>Operating Cashflow / Current Liabilities</td>
                                <td>
                                    Measures how many times current liabilities are covered by operating cashflows
                                    produced during the period. Positive values greater than 1 indicated good ability to
                                    produce cash to cover short term liabilities. Positive values in range 0 to 1
                                    indicate that while the company produces positive operating cash flows, these are
                                    not enough to cover short term liabilities and thus, other sources of cash
                                    (investing and / or financing) would be required. Negative CLCC values indicate that
                                    Current Liabilities would need to be covered by financing or investing sources.
                                </td>
                            </tr>
                            <tr>
                                <td>Operating Cash to<br/>Sales Ratio (OCS)</td>
                                <td>Operating Cashflow / Sales</td>
                                <td>
                                    Measures the ratio between cash flow produced from operations and sales during the
                                    same period. In other words, it measures how many dollars of operating cash are
                                    generated for each dollar of sales.
                                </td>
                            </tr>
                            <tr>
                                <td>Quality of Payment Terms (QPT)</td>
                                <td>f1(DSO)</td>
                                <td>
                                    Measures the quality of the collection function. f1 is a non-linear transformation
                                    of DSO (Days Sales Outstanding) to better assess quality of payment terms. Companies
                                    that are paid within 30 days or less are scored at 1. Companies with DSO greater
                                    than 90 days are scored at -1. Companies that are paid in average within 30 to 90
                                    days get a score between +1 and -1, with QPT = 0 at 60 days. QPT equally values
                                    companies that collect within 30 days at +1, or later than 90 days at -1,
                                    irrespective of the actual number of days. A company that collects at 100 days is
                                    deemed equally sub performing than a company that collects at 120 days.
                                </td>
                            </tr>
                            <tr>
                                <td>Quality of Operating<br/>to Finance Funds<br/>Use Ratio (QOFFUR)</td>
                                <td>f2(Operating Cashflow, Financing Cashflow)</td>
                                <td>
                                    Score in the range -1 to +1. It measures the quality of the cash flow from
                                    operations relative to cash flow from financing activities. Both positive and
                                    negative values of operating and financing cashflows are acceptable or questionable
                                    under different circumstances. Thus, QOFFUR takes a more nuanced approach to assess
                                    the quality. At one end, a company that produces operating cashflows (CFop &gt; 0)
                                    and
                                    uses part of it to pay lenders back (CFfin &lt; 0) gets a score of 1. A company that
                                    has
                                    net negative operating cashflows and net negative financing cashflows is a company
                                    that continues to borrow without being able to produce positive cashflows. It gets a
                                    score of -1. QOFFUR values between +1 and -1 address different permutations of CFop
                                    and CFfin polarities and values.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <h2>What are FinRatio&apos;s macroeconomic-related financial performance ratios?</h2>
                        <table className="table table-striped table-hover">
                            <tbody>
                            <tr>
                                <td>Liabilities to Yield<br/>Curve Alignment (LYCA)</td>
                                <td>YC Rate *<br/>(CL - LT Liabilities) / Liabilities</td>
                                <td>
                                    Measures the alignment between the debt structure of the company and the
                                    macroeconomic interest rates determined by the Yield Curve. Positive values indicate
                                    a better alignment of the company&apos;s short-term vs. long-term debt with market
                                    ST/LT
                                    rates. Negative values indicate misalignment. For example, a company with 80% LT
                                    debt and 20% ST debt when Yield Curve = -1% (meaning ST rates are higher than LT
                                    rates by 1%) would yield a LYCA value of +0.006. The company debt structure of
                                    favors LT debt, which has lower rates. Thus, company&apos;s LYCA value is positive,
                                    indicating good alignment.
                                </td>
                            </tr>
                            <tr>
                                <td>Inflation-Adjusted Inventory<br/>Carry-on Cost (IAICOC)</td>
                                <td>(Inventory / Assets) *<br/>(1 + i)^(d / 365)</td>
                                <td>
                                    Measures the economic cost of carrying inventories given expected inflation rates
                                    and company’s inventory turnover. In inflationary situations, the company is
                                    penalized if carrying larger inventories relative to assets. The IAICOC value is a
                                    function of the company&apos;s inventory turnover (average days in inventory), its
                                    inventory levels relative to assets, and the inflation rate.
                                </td>
                            </tr>
                            <tr>
                                <td>ROA to Bond Rate (ROA2bond)</td>
                                <td>((1 + ROA) ^ 4 - 1) /<br/>Bond Rate</td>
                                <td>
                                    Measures company&apos;s Return on Assets relative to the prevalent average cost of
                                    borrowing determined by Moody&apos;s BAA bond rate. It is intended to measure if a
                                    company yields better returns than cost of borrowing..
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <h2>What information is needed for a CFOdigital company report?</h2>
                        <ul>
                            <li>
                                Just the latest (current) set of quarterly financial statements: Balance Sheet, Income
                                Statement and Statement of Cashflows.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}