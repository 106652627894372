import React from 'react';

const BlurView = ({children}: { children: any }) => {
    return (
        <div className="blur-container">
            <div className="blur-content">{children}</div>
            <div className="blur-overlay">
                <svg xmlns="https://www.w3.org/2000/svg" version="1.1" height="0">
                    {/* Hack to keep image from getting cut off: */}
                    {/* https://iamsteve.me/blog/overcoming-a-couple-of-issues-with-svg-filter-effects*/}
                    <filter id="blurViewFilter" width="150%" height="150%" x="-25%" y="-25%" >
                        <feGaussianBlur stdDeviation="5"/>
                    </filter>
                </svg>
            </div>
        </div>
    );
};

export default BlurView;