import {useStripe} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";

import {useAuth} from "../AuthProvider";
import {postData} from "../api/postData";
import {PostStatus} from "../api/postStatus";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";

export default function useUserSubscribe() {
    const stripe = useStripe();
    const {getToken, setUser} = useAuth();

    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    const [productId, setProductId] = useState('');
    const [priceId, setPriceId] = useState('');
    const [isFree, setIsFree] = useState(false);
    const [stripeElements, setStripeElements] = useState<any>(null);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (
            productId: string,
            priceId: string,
            isFree: boolean) => {

            const elements = stripeElements;

            if (!stripe || !elements) {
                return;
            }

            if (!isFree) {
                // Trigger form validation and wallet collection.
                const {error: submitError} = await elements.submit();

                if (submitError) {
                    setStatus(PageStatus.HasError);
                    return;
                }
            }

            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                const message = results.message;

                if (results.postStatus === PostStatus.UserEmailUnverified) {
                    setStatus(PageStatus.UserEmailUnverified);
                    setMessage(message);
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            //
            // Create customer and subscription and get token for finalizing subscription.
            //

            const data = {
                "product_id": productId,
                "price_id": priceId
            };

            const apiResults = await postData(ApiEndpoints.userInitializeSubscription, data, headers, signal);

            if (doExitEarly(apiResults)) {
                return;
            }

            const subscriptionResults = apiResults.results;
            const clientSecret = subscriptionResults[0].client_secret;

            //
            // Confirm payment and activate subscription.
            //

            if (!isFree) {
                // eslint-disable-next-line no-undef
                const returnUrl = process.env.REACT_APP_CLIENT_URL || '';

                // Use the clientSecret and Elements instance to confirm the setup
                // Once the payment is made, a webhook saves the user subscription status.
                const {error} = await stripe.confirmPayment({
                    elements: stripeElements,
                    clientSecret,
                    confirmParams: {
                        return_url: returnUrl,
                    },
                    redirect: 'if_required'
                });

                if (error) {
                    const errorMessage = error.message || 'An unexpected error occurred.';
                    setStatus(PageStatus.HasError);
                    setMessage(errorMessage);
                    return;
                }
            }

            //
            // Refresh user, subscription status, and entitlements.
            //

            const userGetResult = await postData(ApiEndpoints.userGet, {}, headers, signal);

            if (doExitEarly(userGetResult)) {
                return;
            }

            const userGetResults = userGetResult.results;

            // Finish all calls before setting any state or providers.
            setUser(userGetResults[0]);
            setStatus(PageStatus.HasSubmitted);
        };

        postForm(productId, priceId, isFree).then();

        return () => {
            controller.abort();
        };

    }, [status, productId, priceId, isFree, getToken, stripeElements, stripe, setUser]);

    const subscribeUser = (
        productId: string,
        priceId: string,
        isFree: boolean,
        elements: any) => {

        setStatus(PageStatus.IsSubmitting);
        setMessage('');
        setProductId(productId);
        setPriceId(priceId);
        setIsFree(isFree);
        setStripeElements(elements);
    };

    return {
        status, message, validationErrors, subscribeUser
    };
}