import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {useAuth} from "../AuthProvider";
import {postData} from "../api/postData";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";
import {IndustryList} from "../api/misc/industryList";

export default function useIndustryList() {

    const {getToken} = useAuth();

    const [status, setStatus] = useState(PageStatus.IsLoading);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    // Fetched data in correct shape.
    const [industryList, setIndustryList] = useState<IndustryList | null>(null);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async () => {
            if (status !== PageStatus.IsLoading) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    // Reset all results. Don't display partial results.
                    setIndustryList(null);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const industryResults = await postData(ApiEndpoints.industryList, {}, headers, signal);

            if (doExitEarly(industryResults)) {
                return;
            }

            setIndustryList(IndustryList.fromJson(industryResults.results));
            setStatus(PageStatus.IsLoaded);
        };

        postForm().then();

        return () => {
            controller.abort();
        };

    }, [status, getToken]);

    return {
        status, message, validationErrors, industryList
    };
}