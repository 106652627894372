export enum PageStatus {
    IsReady = 'IS_READY',

    // Is page loading data.
    IsLoading = 'IS_LOADING',

    IsLoaded = 'IS_LOADED',

    IsMissingCompany = 'IS_MISSING_COMPANY',

    IsMissingReport = 'IS_MISSING_REPORT',

    // Is saving or submitting to the API.
    IsSubmitting = 'IS_SUBMITTING',

    // The token is expired.
    RequiresAuth = 'REQUIRES_AUTH',

    UserEmailUnverified = 'USER_EMAIL_UNVERIFIED',

    // Has connectivity or validation errors.
    HasError = 'HAS_ERROR',

    // The save or submit has been successful.
    HasSubmitted = 'HAS_SUBMITTED'
}