import {AccountCategoryKeys} from "../reports/accountCategoryKeys";
import {Benchmark} from "./benchmark";
import {BenchmarkKey} from "./benchmarkKey";
import {BENCHMARK_KEYS_ORDER} from "./benchmarkKeysOrder";

export function sort(benchmarks: BenchmarkKey[]) {

    const sorted: BenchmarkKey[] = [];

    BENCHMARK_KEYS_ORDER.forEach(function (benchmarkKey: string) {
        benchmarks.forEach(function (benchmark: BenchmarkKey) {
            if (benchmark.benchmarkKey === benchmarkKey) {
                sorted.push(benchmark);
                return;
            }
        });
    });

    return sorted;
}

export class BenchmarkList {
    benchmarks: Benchmark[] = [];

    static fromJson(json: any): BenchmarkList {
        const obj = new BenchmarkList();
        const benchmarkList: Benchmark[] = [];

        json.forEach(function (item: any) {
            benchmarkList.push(Benchmark.fromJson(item));
        });

        obj.benchmarks = sort(benchmarkList) as Benchmark[];

        return obj;
    }

    getAssets() {
        const benchmarkList: Benchmark[] = [];

        this.benchmarks.forEach(function (item: Benchmark) {
            if (item.accountCategoryKey === AccountCategoryKeys.ASSETS) {
                benchmarkList.push(item);
            }
        });

        return benchmarkList;
    }

    getLiabilitiesAndEquity() {
        const benchmarkList: Benchmark[] = [];

        this.benchmarks.forEach(function (item: Benchmark) {
            if (item.accountCategoryKey === AccountCategoryKeys.LIABILITIES ||
                item.accountCategoryKey === AccountCategoryKeys.EQUITY) {
                benchmarkList.push(item);
            }
        });

        return benchmarkList;
    }
}