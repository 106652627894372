import * as React from "react";
import {useState} from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {useAuth} from "../../AuthProvider";
import {EntitlementTypes} from "../../api/entitlementTypes";
import {Strings} from "../../locales/strings";
import FormError from "../../components/FormError";
import {PageStatus} from "../pageStatus";
import useReportDownload from "../../hooks/useReportDownload";
import AnalysisCharts from "./AnalysisCharts";

export default function AnalysisContent({company, reportList}: any) {
    const {getEntitlement} = useAuth();

    const companyId = company.companyId;
    const companyName = company.companyName;
    const sectorKey = company.sectorKey;
    const defaultReportDate = reportList[0].periodEndDate;

    const [reportDate, setReportDate] = useState<string>(defaultReportDate);

    const {
        status: downloadStatus,
        message: downloadMessage,
        downloadReport
    } = useReportDownload(companyId, companyName, reportDate);

    const handleDownload = () => {
        downloadReport();
    };

    const handleReportDateChange = (event: any) => {
        setReportDate(event.target.value);
    };

    const isDownloadAuthorized = getEntitlement(EntitlementTypes.REPORTS_EXPORT) || false;

    return (
        <div className="content analysis">
            <div className="row mb-4">
                <div className="col">
                    <h2>Select your report</h2>
                </div>
                <div className="col-auto">
                    <select
                        id="reportDate"
                        className="form-select form-select-sm"
                        disabled={downloadStatus === PageStatus.IsSubmitting}
                        onChange={handleReportDateChange}
                    >
                        {reportList.map((e: any) =>
                            <option
                                value={e.periodEndDate}
                                key={e.periodEndDate}>
                                {e.periodEndDate} (Q{e.quarter})
                            </option>)}
                    </select>
                </div>
                <div className="col-auto">
                    {
                        isDownloadAuthorized ?
                            (
                                <Button
                                    variant="primary"
                                    size="sm"
                                    disabled={downloadStatus === PageStatus.IsSubmitting}
                                    onClick={handleDownload}>
                                    Download
                                </Button>
                            ) : <></>
                    }

                    {!isDownloadAuthorized ?
                        (
                            <OverlayTrigger
                                placement="auto"
                                delay={{show: 0, hide: 700}}
                                overlay={(
                                    <Popover>
                                        <Popover.Body>
                                            {Strings.FEATURE_DISABLED}
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    disabled={downloadStatus === PageStatus.IsSubmitting}
                                >
                                    Download
                                </Button>
                            </OverlayTrigger>
                        ) : <></>
                    }
                </div>
            </div>

            <FormError status={downloadStatus} message={downloadMessage}/>

            <div className="row">
                <div className="col">
                    <AnalysisCharts
                        key={reportDate}
                        companyId={companyId}
                        sectorKey={sectorKey}
                        reportDate={reportDate}
                    />
                </div>
            </div>
        </div>
    );
}