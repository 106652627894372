import {KpiCategoryKeys} from "./kpiCategoryKeys";
import {KpiKey} from "./kpiKey";
import {KPI_KEYS_ORDER} from "./kpiKeysOrder";
import {KpiPrognosis} from "./kpiPrognosis";

export function sort(kpis: KpiKey[]) {

    const sortedKpis: KpiKey[] = [];

    KPI_KEYS_ORDER.forEach(function (kpiKey: string) {
        kpis.forEach(function (kpi: KpiKey) {
            if (kpi.kpiKey === kpiKey) {
                sortedKpis.push(kpi);
                return;
            }
        });
    });

    return sortedKpis;
}

export class KpiPrognosisList {
    kpiPrognoses: KpiPrognosis[] = [];

    static fromJson(json: any): KpiPrognosisList {
        const obj = new KpiPrognosisList();
        const kpiList: KpiPrognosis[] = [];

        json.forEach(function (item: any) {
            kpiList.push(KpiPrognosis.fromJson(item));
        });

        // Sort according to UI requirements.
        obj.kpiPrognoses = sort(kpiList) as KpiPrognosis[];

        return obj;
    }

    getCategoryName(categoryKey: KpiCategoryKeys) {
        let name = '';

        this.kpiPrognoses.forEach(function (kpi: KpiPrognosis) {
            if (kpi.kpiCategoryKey === categoryKey) {
                name = kpi.kpiCategoryName;
                return;
            }
        });

        return name;
    }

    getKpisByCategory(categoryKey: KpiCategoryKeys) {
        const results: KpiPrognosis[] = [];

        this.kpiPrognoses.forEach(function (kpi: KpiPrognosis) {
            if (kpi.kpiCategoryKey === categoryKey) {
                results.push(kpi);
            }
        });

        return results;
    }
}