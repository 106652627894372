import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {useAuth} from "../AuthProvider";
import {postData} from "../api/postData";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";

export default function useReportAdd(companyId: string) {
    const {getToken} = useAuth();

    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (data: any) => {
            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);
                    setFormData({});

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            data['company_id'] = companyId;

            const userResults = await postData(ApiEndpoints.companyReportsCreate, data, headers, signal);

            if (doExitEarly(userResults)) {
                return;
            }

            setStatus(PageStatus.HasSubmitted);
        };

        postForm(formData).then();

        return () => {
            controller.abort();
        };

    }, [status, formData, companyId, getToken]);

    const addReport = (data: any) => {
        setStatus(PageStatus.IsSubmitting);
        setMessage('');
        setValidationErrors([]);

        // Date input includes full date string. Truncate to correct format.
        data['period_end_date'] = data['period_end_date'].toISOString().split('T')[0];

        // API needs value as integer.
        data['quarter'] = parseInt(data.quarter);

        setFormData(data);
    };

    return {
        status, message, validationErrors, addReport
    };
}