import * as React from "react";
import {Strings} from "../../locales/strings";

export function ErrorCard({description1, description2}: any) {

    let message1 = Strings.ERROR_SOMETHING;
    let message2 = '\u00A0';

    if (description1) {
        message1 = description1;
    }

    if (description2) {
        message2 = description2;
    }

    return (
        <>
            <h5 className="card-title">
                <div>{message1}</div>
                <div>{message2}</div>
            </h5>
            <div className="charts-error text-center">
                <i className="bi bi-exclamation-triangle text-danger"></i>
            </div>
        </>
    );
}
