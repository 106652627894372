import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {useAuth} from "../AuthProvider";
import {postData} from "../api/postData";
import {PostStatus} from "../api/postStatus";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";

/** Posts data to an API. Updates status from IsReady -> IsSubmitting -> HasSubmitted. **/
export default function usePostData() {

    const {getToken, setUser} = useAuth();

    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [url, setUrl] = useState('');

    const [formData, setFormData] = useState<any>({});
    const [results, setResults] = useState<any | null>(null);
    const [returnMessage, setReturnMessage] = useState(false);
    const [reloadUser, setReloadUser] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (url: string, data: any) => {
            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                const message = results.message;

                if (results.postStatus === PostStatus.UserEmailUnverified) {
                    setStatus(PageStatus.UserEmailUnverified);
                    setMessage(message);
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    setMessage(message);
                    return true;
                }

                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                if (returnMessage) {
                    setMessage(message);
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const postResults = await postData(url, data, headers, signal);

            if (doExitEarly(postResults)) {
                return;
            }

            if (postResults.results) {
                const results = postResults.results;
                setResults(results);
            }

            //
            // Refresh user, subscription status, and entitlements.
            //

            if (reloadUser) {
                const userGetResult = await postData(ApiEndpoints.userGet, {}, headers, signal);

                if (doExitEarly(userGetResult)) {
                    return;
                }

                const userGetResults = userGetResult.results;
                setUser(userGetResults[0]);
            }

            setStatus(PageStatus.HasSubmitted);
        };

        postForm(url, formData).then();

        return () => {
            controller.abort();
        };

    }, [status, getToken, url, formData, returnMessage, reloadUser, setUser]);

    const submitForm = (url: string, data: any, returnMessage: boolean = false, reloadUser: boolean = false) => {
        setStatus(PageStatus.IsSubmitting);
        setMessage('');
        setValidationErrors([]);
        setResults(null);
        setReturnMessage(returnMessage);
        setReloadUser(reloadUser);

        setUrl(url);
        setFormData(data);
    };

    const resetState = () => {
        setStatus(PageStatus.IsReady);
        setMessage('');
        setValidationErrors([]);
        setResults(null);
        setReturnMessage(false);
        setReloadUser(false);
        setUrl('');
        setFormData({});
    };

    return {
        status, message, validationErrors, submitForm, resetState, formData, results
    };
}