import React, {useState} from 'react';
import {Alert} from "react-bootstrap";
import {Outlet, useLocation} from 'react-router-dom';
import './App.css';
import {useAuth} from "./AuthProvider";
import Breakpoints from "./components/Breakpoints";
import {useCompany} from "./CompanyProvider";
import {PageEndpoints} from "./pages/pageEndpoints";
import Footer from "./components/Footer";
import Header from "./pages/public/Header";
import Menu from './components/Menu';
import {CustomizedState} from "./utils/customizedState";

const publicRoutes = [
    PageEndpoints.home,
    PageEndpoints.publicContactUs,
    PageEndpoints.publicFaqs,
    PageEndpoints.publicPricing,
    PageEndpoints.publicPrivacyPolicy,
    PageEndpoints.publicTermsOfUse,

    PageEndpoints.userChangePasswordConfirmation,
    PageEndpoints.userChangePasswordRequest,
    PageEndpoints.userChangePasswordStatus,
    PageEndpoints.userSubscription,
    PageEndpoints.userEmailVerification,
    PageEndpoints.userEmailVerificationRequest,
    PageEndpoints.userEmailVerificationStatus,
    PageEndpoints.userSignIn,
    PageEndpoints.userSignOut,
    PageEndpoints.userSignUp,
    PageEndpoints.notFound
];

const App = () => {
    const location = useLocation();
    const state = location.state as CustomizedState;
    const {isAuth} = useAuth();

    const [showError, setShowError] = useState(true);
    const [showSuccess, setShowSuccess] = useState(true);

    const {getCurrentCompanyId} = useCompany();
    const companyId = getCurrentCompanyId();
    console.log('APP companyId: ' + companyId);

    let errorMessage = '';
    let successMessage = '';

    if (state) {
        errorMessage = state?.error || '';
        successMessage = state?.message || '';
    }

    // Public routes. Shows marketing links on top menu.
    if (publicRoutes.includes(location.pathname) || !isAuth()) {
        return (
            <div className="public vh-100 d-flex flex-column">
                <Header/>

                <div className="container-fluid mt-5 pt-3">
                    <div className="row bg-light">
                        <div className="col">
                            <Outlet/>
                        </div>
                    </div>
                </div>

                <div className="mt-auto">
                    <Footer/>
                </div>

                <Breakpoints/>
            </div>
        );
    }

    // Private routes. Shows account links on side menu.
    return (
        <div className="container-fluid overflow-hidden">
            <div className="row vh-100 overflow-auto">
                <div className="menu col-sm-auto sticky-top">
                    <Menu/>
                </div>

                <div className="col d-flex flex-column h-100">
                    <main className="row overflow-auto flex-shrink-0">
                        {
                            showError && errorMessage &&
                            <Alert
                                className="global-message"
                                variant="danger"
                                onClose={() => setShowError(false)}
                                dismissible>
                                {errorMessage}
                            </Alert>
                        }

                        {
                            showSuccess && successMessage &&
                            <Alert
                                className="global-message"
                                variant="success"
                                onClose={() => setShowSuccess(false)}
                                dismissible>
                                {successMessage}
                            </Alert>
                        }

                        <Outlet/>
                    </main>

                    <div className="mt-auto">
                        <Footer/>
                    </div>
                </div>
                <Breakpoints/>
            </div>
        </div>
    );
};

export default App;
