export class CompanyBenchmarksGetCriteria {
    companyId: string = '';
    periodEndDate: string = '';

    toJson() {
        return {
            "company_id": this.companyId,
            "period_end_date": this.periodEndDate
        };
    }
}

export class CompanyKpisGetCriteria {
    companyId: string = '';
    periodEndDate: string = '';

    toJson() {
        return {
            "company_id": this.companyId,
            "period_end_date": this.periodEndDate
        };
    }
}

export class CompanyKpiPrognosesGetCriteria {
    companyId: string = '';
    periodEndDate: string = '';
    kpiCategoryKeys: string[] = [];

    toJson() {
        return {
            "company_id": this.companyId,
            "period_end_date": this.periodEndDate,
            "kpi_category_keys": this.kpiCategoryKeys
        };
    }
}

export class IndustryBenchmarksGetCriteria {
    sectorKey: string = '';
    periodEndDate: string = '';
    benchmarkKeys: string[] = [];

    toJson() {
        return {
            "sector_key": this.sectorKey,
            "period_end_date": this.periodEndDate,
            "benchmark_keys": this.benchmarkKeys
        };
    }
}

export class IndustryKpisAggregatesGetCriteria {
    sectorKey: string = '';
    periodEndDate: string = '';
    aggKeys: string[] = [];

    toJson() {
        return {
            "sector_key": this.sectorKey,
            "period_end_date": this.periodEndDate,
            "agg_keys": this.aggKeys
        };
    }
}

export class IndustryKpiPrognosesGetCriteria {
    sectorKey: string = '';
    periodEndDate: string = '';
    kpiCategoryKeys: string[] = [];

    toJson() {
        return {
            "sector_key": this.sectorKey,
            "period_end_date": this.periodEndDate,
            "kpi_category_keys": this.kpiCategoryKeys
        };
    }
}