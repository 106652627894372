// TODO: Rename file to formUtils. Move other function to new file.
export function getFieldToFocus(formErrors: any, defaultField: string) {
    if (formErrors === undefined || isEmpty(formErrors)) {
        return defaultField;
    }

    const fieldNames = Object.keys(formErrors);

    return fieldNames[0];
}

export function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
}
