export class ReportEntry {
    accountKey: string = '';
    accountName: string = '';
    accountCategory: string = '';
    amount: number = 0;

    static fromJson(json: any): ReportEntry {
        const obj = new ReportEntry();

        obj.accountKey = json.account_key;
        obj.accountName = json.account_name;
        obj.accountCategory = json.account_category;
        obj.amount = json.amount;

        return obj;
    }
}