// Do not change the order of these. Used in UI.
export const KPI_KEYS_ORDER = [
    'current_ratio',
    'quick_ratio',
    'cash_ratio',

    'dso',
    'adi',
    'adp',
    'cash_cycle',

    'ar_turnover',
    'inventory_turnover',
    'ap_turnover',
    'wc_turnover',
    'total_asset_turnover',
    'fixed_asset_turnover',
    'tangible_asset_turnover',
    'ppe_turnover',

    'roa',
    'roe',

    'net_margin',
    'operating_margin',
    'basic_earning_power',
    'roce',

    'debt_ratio',
    'long_term_debt_ratio',
    'de_ratio',

    'ncg',
    'ocg',
    'clcc',
    'ocs',
    'qpt',
    'qoffur',

    'lyca',
    'iaicoc',
    'roa2bond'
];