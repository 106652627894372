import {UserEntitlement} from "./userEntitlement";

export class UserEntitlementList extends Array<UserEntitlement> {

    static fromJson(json: any): UserEntitlementList {
        const obj = new UserEntitlementList();

        json.forEach(function (item: any) {
            obj.push(UserEntitlement.fromJson(item));
        });

        return obj;
    }

    /* For all prognoses entitlements, get the categories that are authorized. */
    getAuthorizedPrognosisCategoryKeys(): string[] {
        const list: string[] = [];
        const prefix = 'prognoses_';

        this.forEach(function (item: UserEntitlement) {
            const key = item.entitlementKey;
            const value = item.entitlementValue;

            if (key.startsWith(prefix) && value) {
                const categoryName = key.slice(key.indexOf(prefix) + prefix.length);
                list.push(categoryName);
            }
        });

        return list;
    }
}