import * as React from 'react';
import {Link} from 'react-router-dom';

import {PageEndpoints} from '../pageEndpoints';

export default function NotFound() {
    return (
        <div className="row justify-content-center py-5">
            <div className="col- 10 col-md-8 col-lg-4">
                <h1 className="mb-4">Page not found.</h1>
                <div className="mb-2">
                    Sorry, an error has occurred. The requested resource was not found.
                </div>
                <div>
                    <Link to={PageEndpoints.home} className="float-start">Home</Link>
                </div>
            </div>
        </div>
    );
}