import * as React from 'react';
import {round} from '../../utils/numberUtils';
import {Kpi} from "../../api/metrics/kpi";

export default function KpiGroup({kpis, categoryName}: any) {

    return (
        <div className="card">
            <div className="card-header">
                <h3>{categoryName}</h3>
            </div>
            <div className="card-body">
                <table className="table">
                    <tbody>
                    {kpis.map((item: Kpi) => (
                        <tr key={item.kpiKey}>
                            <td>{item.kpiName}</td>
                            <td className="text-end">
                                {
                                    item.kpiValue === null ? 'N/A' : round(item.kpiValue, 5)
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
