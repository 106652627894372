import * as React from "react";
import {useCompany} from "../../CompanyProvider";
import PageError from "../../components/PageError";
import Status from "../../components/Status";
import {PageStatus} from "../pageStatus";
import useCompanyReport from "../../hooks/useCompanyReport";
import {getCurrentCompany} from "../../api/companies/companyUtils";
import CompanyEdit from "./CompanyEdit";
import CompanyHeader from "./CompanyHeader";

export function Company({companyId}: any) {

    // TODO: BUG in useCompanyReport. Will not refresh current company info if ID has not changed.
    const {status, message} = useCompanyReport(companyId);
    const {getCompanies} = useCompany();

    const companyList = getCompanies();
    const company = getCurrentCompany(companyList, companyId);

    let template;

    if (status === PageStatus.IsLoading) {
        template = (<Status message="Loading company"/>);
    } else if (status === PageStatus.HasError) {
        template = (<PageError message={message}/>);
    } else {
        template = (<CompanyEdit companyId={companyId}/>);
    }

    return (
        <>
            <CompanyHeader company={company}/>
            {template}
        </>
    );
}