// Do not change the order of these. Used in UI.
export const BENCHMARK_KEYS_ORDER = [

    // Assets.
    'cash_pct',
    'accounts_receivable_pct',
    'inventory_pct',
    'other_current_assets_pct',
    'ppe_pct',
    'intangibles_pct',
    'other_noncurrent_assets_pct',

    // Liabilities + Equity.
    'accounts_payable_pct',
    'notes_interest_debt_pct',
    'other_accrued_liabilities_pct',
    'other_current_liabilities_pct',
    'noncurrent_liabilities_pct',
    'equity_pct'
];