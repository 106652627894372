export const Strings = {
    COMPANY_NAME: "FinRatio Corp.",

    ERROR_SOMETHING: "Something went wrong.",
    ERROR_UNEXPECTED: "An unexpected error has occurred.",

    CHART_GENERATING_ANALYSIS: "Generating analysis...",

    USER_SUBSCRIPTION_CANCEL_BUTTON: "Cancel subscription",
    USER_SUBSCRIPTION_CANCEL_SUCCESS: "Your subscription has been canceled!",
    USER_SUBSCRIPTION_CANCEL_TITLE: "Cancel subscription",
    USER_SUBSCRIPTION_RENEWS_MONTHLY: "Automatically renews monthly. Cancel anytime.",
    USER_SUBSCRIPTION_RENEWS_YEARLY: "Automatically renews yearly. Cancel anytime.",
    USER_SUBSCRIPTION_SUCCESS: "Subscription successful! Get started!",

    FEATURE_DISABLED: "This feature is disabled for your plan. To activate it,\ncontact FinRatio to upgrade your account.",
    FEATURE_LIMIT: "You have reached your subscription limit. Contact FinRatio to upgrade your account."
};